import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Helmet } from 'react-helmet';
import { processDataTemplate } from '../../cms/utils';
import { Row, Col } from 'antd';

import SuccessStoriesList from './SuccessStoriesList';
const HOMEMAIN_QUERY = loader('../Home/Modules/HomeMain/homemain.query.gql');
const successStoriesLoad = ['SuccessStories'];
const successStoriesLoadType = ['data'];
const aContentcodes = ['hp-header', 'hp-under-header', 'hp-AppelProjet'];
import { HomemainQuery, HomemainQueryVariables } from '../../operation-result-types';

const SuccessStories = () => {
  const { data } = useQuery<HomemainQuery, HomemainQueryVariables>(HOMEMAIN_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      sections: JSON.stringify(successStoriesLoad),
      contentcodes: JSON.stringify(aContentcodes),
      page: 0
    }
  });
  const dataContentSection = data?.cmsSectionContents ? data.cmsSectionContents.nodes : [];
  const successStoriesData: any = {};
  successStoriesLoad.forEach((template, index) => {
    successStoriesData[template] = processDataTemplate(
      dataContentSection,
      template,
      successStoriesLoadType[index]
    );
  });
  return (
    <div>
      <Helmet>
        <title>Success Stories</title>
        <meta name="description" content="leHub: Suc;cess factories" data-react-helmet="true" />
        <meta property="og:title" content="Success Stories" />
        <meta property="og:description" content="Success Stories" data-react-helmet="true" />
      </Helmet>
      <Row>
        <Col
          offset={4}
          className="Navigation"
          style={{
            gap: '8px',
            color: '#001D4A'
          }}
        >
          <a href="/">Accueil</a>
          <a href="">Success Stories</a>
        </Col>
      </Row>

      <SuccessStoriesList sectionData={successStoriesData['SuccessStories']} />
    </div>
  );
};

export default SuccessStories;
