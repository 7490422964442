import { OverlayProvider } from '@react-aria/overlays';
import { ConfigProvider, notification, Spin } from 'antd';
import en_GB from 'antd/lib/locale-provider/en_GB';
import fr_FR from 'antd/lib/locale-provider/fr_FR';
import moment from 'moment';
import * as queryString from 'query-string';
import React, { useState, useEffect, useMemo } from 'react';
import Helmet from 'react-helmet';
import Loadable from 'react-loadable';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import './app.less';
import Layout from './Modules/Layout/Layout';
import CmsRouter from './Scenes/NotFound/CmsRouter';
import SuccessStories from './Scenes/SuccessStories/SuccessStories';
import SuccessStory from './Scenes/SuccessStories/SuccessStory/SuccessStory';
require('moment/locale/fr.js');

notification.config({
  placement: 'topRight',
  top: 70
});

function determineLocaleFromStorage() {
  const supportedLocales = ['fr', 'en'];

  const storedLocale = localStorage.getItem('i18nextLng') || 'fr';

  if (supportedLocales.indexOf(storedLocale) === -1) {
    return 'fr';
  } else {
    return storedLocale;
  }
}

const locales = {
  fr: fr_FR,
  en: en_GB
};

const StartupLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "Startup" */ './Scenes/Startup/Startup'),
  delay: 300,
  loading: () => <Spin />
});

const OrganizationLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "Organization" */ './Scenes/Organization/Organization'),
  delay: 300,
  loading: () => <Spin />
});

const AcceleratorLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "Accelerator" */ './Scenes/Accelerator/Accelerator'),
  delay: 300,
  loading: () => <Spin />
});

const InvestorLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "Investor" */ './Scenes/Investor/Investor'),
  delay: 300,
  loading: () => <Spin />
});

const OrganizationsLoadable = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Organizations" */ './Scenes/Organizations/Organizations'),
  delay: 300,
  loading: () => <Spin />
});

const HomeStartupLoadable = Loadable({
  loader: () =>
    import(/* webpackChunkName: "HomeStartup" */ './Scenes/Home/Modules/HomeMain/HomeStartup'),
  delay: 300,
  loading: () => <Spin />
});

const CGULoadable = Loadable({
  loader: () => import(/* webpackChunkName: "CGU" */ './Scenes/Static/CGU/CGU'),
  delay: 300,
  loading: () => <Spin />
});

const LegalLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "Legal" */ './Scenes/Static/Legal/Legal'),
  delay: 300,
  loading: () => <Spin />
});

const PartnersLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "Partners" */ './Scenes/Static/Partners/Partners'),
  delay: 300,
  loading: () => <Spin />
});

const NewSearchLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "Search" */ './Scenes/NewSearch'),
  delay: 300,
  loading: () => <Spin />
});

const ProjectCallLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "ProjectCall" */ './Scenes/ProjectCalls/ProjectCalls'),
  delay: 300,
  loading: () => <Spin />
});

const HomeCorporateLoadable = Loadable({
  loader: () =>
    import(/* webpackChunkName: "HomeCorporate" */ './Scenes/Home/Modules/HomeMain/HomeCorporate'),
  delay: 300,
  loading: () => <Spin />
});

type Props = {
  children: React.ReactNode;
} & RouteComponentProps<{}>;

const App = (props: Props) => {
  const [locale, setLocale] = useState<string>(determineLocaleFromStorage());
  const { location } = props;

  useEffect(() => {
    // need to remove meta description after is loaded, to avoid conflict and 'helmet' library can inserts meta
    const description = document.querySelector('meta[name="description"]');
    if (description) {
      description.remove();
    }
  }, []);

  useMemo(() => {
    moment.locale(locale);
  }, []);

  const handleLocaleSwitch = (locale: string) => {
    setLocale(locale);
    moment.locale(locale);
  };

  const parsed = queryString.parse(location.search);
  const isTechInFab = parsed && parsed.h === 'techinfab';

  return (
    <React.Fragment>
      {isTechInFab ? (
        <Helmet>
          <link rel="shortcut icon" href="/tf-favicon.ico" />
          <link rel="apple-touch-icon" sizes="180x180" href="/tf-apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/tf-favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/tf-favicon-16x16.png" />
          <link rel="icon" type="image/png" sizes="192x192" href="/tf-android-chrome-192x192.png" />
          <link rel="icon" type="image/png" sizes="512x512" href="/tf-android-chrome-512x512.png" />
        </Helmet>
      ) : (
        <Helmet>
          <link rel="shortcut icon" href="/favicon.ico" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        </Helmet>
      )}
      <OverlayProvider>
        <ConfigProvider locale={locales[locale]}>
          <Layout onLocaleSwitch={handleLocaleSwitch} locale={locale}>
            <Switch>
              <Route
                path="/"
                exact={true}
                component={Loadable({
                  loader: () => import(/* webpackChunkName: "Home" */ './Scenes/Home/Home'),
                  // tslint:disable-next-line:no-any
                  render(loaded: any, props: any) {
                    let Component = loaded.default;
                    return <Component {...props} />;
                  },
                  loading: () => null
                })}
              />
              <Route path="/startups" component={HomeStartupLoadable} />
              <Route path="/corporates" component={HomeCorporateLoadable} />
              <Route path="/cgu" component={CGULoadable} />
              <Route path="/legal" component={LegalLoadable} />
              <Route path="/partners" component={PartnersLoadable} />
              <Route path="/search" component={NewSearchLoadable} />
              <Route path="/projectCalls" component={ProjectCallLoadable} />
              <Route path="/organization/:id" component={OrganizationLoadable} />
              <Route path="/accelerator/:id" component={AcceleratorLoadable} />
              <Route path="/investor/:id" component={InvestorLoadable} />
              <Route path="/organizations" component={OrganizationsLoadable} />
              <Route path="/startup/:startupId([0-9]+)" component={StartupLoadable} />
              <Route path="/startup/:startupSlug" component={StartupLoadable} />
              <Route path="/SuccessStories" component={SuccessStories} />
              <Route path="/SuccessStory/:id" component={SuccessStory} />
              <Route component={CmsRouter} />
            </Switch>
          </Layout>
        </ConfigProvider>
      </OverlayProvider>
    </React.Fragment>
  );
};
export default withRouter(App);
