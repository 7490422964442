import { Breadcrumb, Col, Row } from 'antd';
interface BreadcrumbProps {
  items?: { label?: string; url?: string }[];
  offset?: number;
}
import './CustomBreadcrumb.css';

const CustomBreadcrumb: React.FC<BreadcrumbProps> = ({ items, offset }) => {
  return (
    <Row className="mt-20">
      <Col lg={{ offset }}>
        <Breadcrumb className="breadcumber-container">
          {items.map((item, index) => (
            <Breadcrumb.Item className="breadcumber-item" key={index} href={item.url}>
              {item.label}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </Col>
    </Row>
  );
};

export default CustomBreadcrumb;
