import styled from '../../../styled-components';

export const ContainerRoots = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  flex-direction: row-reverse;
  align-items: flex-start;
  @media screen and (max-width: 1100px) {
    display: block;
    flex-direction: unset;
  }
`;

export const TagCol = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  max-width: unset;
  margin-bottom: 20px;
  @media screen and (min-width: 1101px) {
    max-width: 300px;
    margin-bottom: 0px;
  }
`;

export const RootCard = styled.div`
  margin-bottom: 22px;
  display: flex;
  flex-wrap: wrap;
  max-width: 763px;
  gap: 31px;
`;

export const Card = styled.div`
  height: 398px;
  width: 354px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  & > a {
    color: #000000;
    display: block;
    &:hover {
      text-decoration: none;
      color: auto;
    }
  }
  @media screen and (max-width: 900px) {
    width: calc(100% - 20px);
  }
`;

export const BigCard = styled.div`
  height: 398px;
  width: 740px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  & > a {
    color: #000000;
    display: block;
    &:hover {
      text-decoration: none;
      color: auto;
    }
  }
  @media screen and (max-width: 900px) {
    width: calc(100% - 20px);
  }
`;
export const CardWrapper = styled.div`
  margin: 0px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const Name = styled.div`
  & > a {
    color: #000000;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const Plus = styled.div`
  position: absolute;
  width: 26px;
  height: 25px;
  right: 18px;
  bottom: 35px;
`;
