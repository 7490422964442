import styled from '../../styled-components';

export const PaginationWrapper = styled.div`
  opacity: 1;
  color: rgba(7, 26, 57, 1);
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
`;
