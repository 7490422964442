import styled, { media } from '../../../styled-components';
import { Carousel } from 'antd';
export const RootCard = styled.div`
  margin-bottom: 22px;
  margin-top: 20px;
  margin-left: 24px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
  ${media.mobile`
  width: 100%;
  display: block;
  `};
  ${media.tablet`
  width: 94%;
  display: block;
  margin-left: 11px;
  `};
  ${media.tablet2`
  margin-bottom: 14px;
  margin-top: 6px;
  margin-left: 6px;
  `};
`;
export const Card = styled.div`
  height: 190px;
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > a {
    color: #000000;
    display: block;
  }

  &:hover > i {
    color: auto;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }

  flexshrink: 0;
  padding: 25px 0px 0px 45px;
  ${media.mobile`
  height: 190px;
  margin-bottom:10px;
  width: 310px;
  `};
  ${media.tablet`
  height: 160px;
  padding: 14px 0px 0px 22px;
  `};
  ${media.tablet2`
  height: 140px;
  padding: 12px 0px 0px 5px;
  `};
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  /*  @media screen and (max-width: 900px) {
    width: 100%;
  } */
  ${media.tablet`
  width: 94%;
  `};
  ${media.tablet2`
  width: 96%;
  `};
`;

export const Name = styled.div`
  & > a {
    color: #000000;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
  color: var(--bleu-500, #001d4a);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* H6 */
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 26px */
  margin: 10px 10px 10px -50px;
  ${media.mobile`
  margin: 10px 10px 10px -50px;
  `};
  ${media.tablet2`
  margin: 10px 10px 10px 0px;
  `};
  ${media.tablet3`
  margin: 10px 10px 10px 0px;
  `};
`;

export const Plus = styled.div`
  position: absolute;
  width: 26px;
  height: 25px;
  right: 18px;
  bottom: 35px;
`;

export const CarouselWrapper = styled(Carousel)`
  height: 270px;
  .ant-carousel .slick-dots-bottom {
    bottom: 0px;
  }
  .ant-carousel .slick-dots li button {
    margin-bottom: 100px;
    flex-shrink: 0;
    background: var(--bleu-700, gray);
    opacity: 0.4;
  }

  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: var(--bleu-500, #001d4a);
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover {
    font-size: inherit;
    color: currentColor;
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-prev:hover {
    left: -20px;
    z-index: 2;
    color: black;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
  }

  .ant-carousel .slick-next,
  .ant-carousel .slick-next:hover {
    right: -20px;
    z-index: 2;
    color: black;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
  }
  .ant-carousel .slick-slide {
    width: 350px;
  }

  ${media.mobile`
  & > .ant-carousel .slick-slider .slick-list [aria-hidden='true'] div {
    display: none;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: 0px;
  }
  .ant-carousel .slick-dots li button {
    margin-bottom: 100px;
    flex-shrink: 0;
    opacity: 0.4;
    border-radius: 100px;
    background: var(--bleu-700, #1E88E5);
width: 8px;
height: 8px;
flex-shrink: 0;

  }

  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: var(--bleu-500, #001d4a);
    border-radius: 100px;
  width: 8px;
height: 8px;
flex-shrink: 0;
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover {
    font-size: inherit;
    color: currentColor;
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-prev:hover {
    left: -20px;
    z-index: 2;
    color: black;
    flex-shrink: 0;
    width: 0px;
    height: 0px;
  }

  .ant-carousel .slick-next,
  .ant-carousel .slick-next:hover {
    right: -20px;
    z-index: 2;
    color: black;
    flex-shrink: 0;
    width: 0px;
    height: 0px;
  }
`};
`;
