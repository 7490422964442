import LogoSVG from './image 30.png';
import styled, { media } from '../../styled-components';
import { Collapse as AntCollapse } from 'antd';
export const Image = styled.div`
  float: left;
  @media screen and (max-width: 920px) {
    display: none;
  }
`;

export const PanelTitle = styled.div`
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 120.4%;

  /* or 46px */
  display: flex;
  align-items: center;

  color: #000000;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1140px;
  gap: 25px;
  ${media.mobile`
  gap: 0px;
  box-shadow: 0px 4px 16px 0px rgba(1, 11, 27, 0.15);
  `}
`;

export const BlockGray = styled.div`
  /* fond-neutre */
  padding: 40px 60px 24px 60px;
  background: #f2f2fb;
  border-radius: 0% 11% 10% 0%/ 55%;
  border-radius: 40px 50px 50px 0 / 50px;
  height: 280px;
  width: 450px;
  margin: -51px 30px 0px 0px;
  @media screen and (max-width: 700px) {
    width: 100%;
    margin: auto;
  }
  border-radius: 50% 11% 10% 0%/ 50%;
  height: 274px;
  width: 444px;
  margin: -51px 30px -1px -16px;
  border-radius: 18% 11% 10% 0%/ 75%;
  clip-path: ellipse(444px 274px at 11% 52%);

  ${media.mobile`
  border-radius: 48px 48px 0px 0px;
  width: 100%;
  margin: -50px 0px 0px 0px;
  display: flex;
padding: 32px 16px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
align-self: stretch;
box-shadow: 0px 0px 10px 0px rgba(28, 28, 28, 0.15);
  `}
`;

export const Root = styled.footer`
  width: 100%;
  max-width: 100%;
  padding: 50px 0px 5px 0px;
  display: flex;
  flex-direction: column;
  & > a {
    display: block;
    padding: 9px 16px 10px;
    color: #dddddd;
    font-weight: bold;
    &:hover {
      color: #eeeeee;
    }
  }
  ${media.mobile`
    height: auto;
    margin-top: 50px;
    /* .ant-collapse > .and-collapse-item {  border-bottom: 0px solid white;  }
    &.ant-collapse-borderless > .ant-collapse-item {
       border-bottom: 0px solid #395179;  */
  }

  `}
  border-radius: 48px 48px 0px 0px;
  box-shadow: 0px 4px 32px 0px rgba(28, 28, 28, 0.15);
  margin-top: 50px;
`;

export const LinkWhite = styled.div`
  margin-bottom: 10px;
  & > a {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;

    /* or 25px */

    color: #071a39;
    &:hover {
      color: #071a39;
    }
  }
  ${media.mobile`
    margin-top: 10px;
  `}
`;

export const LinkWhite1 = styled.div`
  margin-bottom: 0px;
  margin-top: 10px;
  margin-left: 25px;
  & > a {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;

    /* or 25px */

    color: #071a39;
    &:hover {
      color: #071a39;
    }
    color: var(--bleu-500, #001d4a);
    font-feature-settings: 'clig' off, 'liga' off;
    /* P1/Medium */
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    ${media.mobile`
    height: auto;
    margin-top: 20px;
    color: var(--Bleu-500, #001D4A);
font-feature-settings: 'clig' off, 'liga' off;
/* P2/Medium */
font-family: IBM Plex Sans;
font-size: 14px;
font-style: normal;
font-weight: 500;
margin-left: -25px;
  `}
  }
`;

export const Logo = styled.img.attrs({
  src: LogoSVG
})`
  width: 135px;
  height: 48.327px;
`;

export const TitleSection = styled.div`
  font-family: IBM Plex Sans Condensed;
  margin-bottom: 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;

  color: #071a39;
  color: var(--bleu-500, #001d4a);
  font-feature-settings: 'clig' off, 'liga' off;
  /* H6 */
  font-family: IBM Plex Sans Condensed;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 26px */
  ${media.mobile`
  margin-bottom: 0px;
  color: var(--Bleu-500, #001D4A);
font-feature-settings: 'clig' off, 'liga' off;

/* H6 */
font-family: IBM Plex Sans Condensed;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 130%; /* 26px */
  `}
`;

export const Social = styled.div`
  padding: 9px 16px 5px 0px;
  & i {
    font-size: 24px;
    color: #fff;
  }
`;

export const Intro = styled.div`
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;

  /* P2/Regular */
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%; /* 16.1px */
`;

export const StyledCollapse = styled(AntCollapse.Panel)`
  width: 100%;
  &&& {
    border-radius: 0px;
    background: white;
  }
  &.ant-collapse-item > .ant-collapse-content {
    border: 0px;
  }
  &.ant-collapse-item {
    border-bottom: 0px;
  }
`;
