import styled from '../../../styled-components';

export const RootCard = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 900px;
`;

export const Card = styled.div`
  flex: 1 1 auto;
  position: relative;

  & > .secondButton {
    position: absolute;
    top: 32px;
    right: 45px;
    cursor: pointer;
    padding: 8px;
    transition: opacity 0.242s ease;
  }

  & > .thirdButton {
    position: absolute;
    top: 32px;
    right: 75px;
    cursor: pointer;
    padding: 8px;
    transition: opacity 0.242s ease;
  }
  & > i {
    position: absolute;
    top: 32px;
    right: 16px;
    cursor: pointer;
    padding: 8px;
    opacity: 0;
    transition: opacity 0.242s ease;
  }

  &:hover > i {
    opacity: 1;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 900px) {
    width: 430px;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const ContainerWidthReducedCenterCard = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
