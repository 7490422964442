import { Col, Card, Row } from 'antd';

interface SuccessStoryCardProps {
  link: string;
  card: {
    cardimg1: string;
    cardimg2: string;
    cardTitle: string;
  };
  cardIndex: number;
  startIndex: number;
  other?: boolean;
}

const SuccessStoryCard: React.FC<SuccessStoryCardProps> = ({
  link,
  card,
  cardIndex,
  startIndex,
  other
}) => {
  const xs = other ? { span: 8, offset: 0 } : 24;
  return (
    <Col key={cardIndex} xs={xs} md={{ span: 8, offset: 0 }}>
      {card && (
        <a href={link}>
          <Card
            style={{
              width: 312,
              height: 224,
              boxShadow: '0px 4px 16px 0px rgba(1, 11, 27, 0.15)'
            }}
          >
            <Row>
              <Col
                span={8}
                offset={3}
                style={{
                  height: '100px',
                  width: '100px',
                  borderRadius: '8px',
                  border: '1px solid var(--wireframe-w-100, #E2E4E9)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden'
                }}
              >
                <img
                  src={card.cardimg1}
                  alt={`Image ${startIndex + cardIndex + 1} - 1`}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain'
                  }}
                />
              </Col>
              <Col
                span={8}
                offset={2}
                style={{
                  height: '100px',
                  width: '100px',
                  borderRadius: '8px',
                  border: '1px solid var(--wireframe-w-100, #E2E4E9)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden'
                }}
              >
                <img
                  src={card.cardimg2}
                  alt={`Image ${startIndex + cardIndex + 1} - 2`}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain'
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <h6 style={{ textAlign: 'center', paddingTop: 24 }}>{card.cardTitle}</h6>
              </Col>
            </Row>
          </Card>
        </a>
      )}
    </Col>
  );
};

export default SuccessStoryCard;
