import { loader } from 'graphql.macro';
import * as queryString from 'query-string';
import { RouteComponentProps, withRouter, RouteProps } from 'react-router-dom';
import Footer from '../../Components/Footer/Footer';
import { LayoutQuery } from '../../operation-result-types';
import Header from '../Header/Header';
import HeaderTech from '../Header/HeaderTech';
import { BannerContent, BannerRoot, BannerWrapper, Root, Wrapper } from './LayoutStyle';
import TechinFabBg from './techinfab-bg.gif';

export const LAYOUT_QUERY = loader('./Layout.query.gql');

interface LayoutProps extends RouteComponentProps<{}> {
  viewer?: LayoutQuery['viewer'];
  refetchViewer?: () => Promise<{}>;
  onLocaleSwitch: (l: string) => void;
  locale: string;
  children?: React.ReactNode;
}

// detect edge
const ua = window.navigator.userAgent;
const edge = ua.indexOf('Edge/') > -1;

const parsed = queryString.parse(location.search);
const headerTech = parsed && parsed.h === 'techinfab' ? true : false;

const Layout = (props: LayoutProps & RouteProps) => {
  const { children, location, onLocaleSwitch } = props;

  if (window.Intercom !== 'undefined') {
    window.Intercom('boot', {
      app_id: window.SERVER_DATA
        ? window.SERVER_DATA.intercomWorkspace
        : process.env.REACT_APP_INTERCOM_WORKSPACE!
    });
  }

  return (
    <Root style={{ backgroundImage: headerTech ? `url(${TechinFabBg})` : undefined }}>
      {/*// @ts-ignore*/}
      {!headerTech && <Header />}
      {headerTech && <HeaderTech />}
      {edge && (
        <BannerRoot>
          <BannerWrapper>
            <BannerContent>
              Le navigateur (Edge/IE) n'est pas compatible avec notre site. Veuillez utiliser un
              autre navigateur (Chrome, Firefox, Safari) pour une meilleure expérience
            </BannerContent>
          </BannerWrapper>
        </BannerRoot>
      )}

      <Wrapper
        style={
          location.pathname !== '/' && !location.pathname.includes('/search')
            ? { marginTop: 50 }
            : {}
        }
      >
        {children}
      </Wrapper>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {/*// @ts-ignore*/}
        {!headerTech && <Footer onLocaleSwitch={onLocaleSwitch} />}
      </div>
    </Root>
  );
};

export default withRouter(Layout);
