import styled from '../../styled-components';

export const Root = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 700px;
`;

export const BannerRoot = styled.div`
  padding: 24px;
  margin: 54px -24px 0px 0px;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-bottom: -54px;
  position: relative;
  z-index: 1;
`;

export const BannerWrapper = styled.div`
  position: relative;
  max-width: 1140px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.white};
`;

export const BannerContent = styled.div`
  position: relative;
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
`;
