import * as React from 'react';

import { Link } from 'react-router-dom';
import { Root } from './ButtonYellow1Style';

// tslint:disable-next-line: no-any
const ButtonComponent: any =
  // tslint:disable-next-line: no-any
  (props: any) => {
    const {
      to,
      href,
      children,
      label,
      size,
      align,
      action,
      bgcolor,
      hoverColor,
      color,
      height,
      border,
      fontWeight,
      borderRadius,
      ...rest
    } = props;

    let content = label || children;
    // tslint:disable-next-line: no-any
    let element: any;
    if (to) {
      element = (
        <Link to={to} {...rest}>
          {content}
        </Link>
      );
    } else if (href) {
      element = (
        <a href={href} {...rest}>
          {content}
        </a>
      );
    } else {
      element = (
        <a onClick={action} {...rest}>
          {content}
        </a>
      );
    }
    return (
      <Root
        width={props.width || null}
        align={align}
        height={height || null}
        color={color || null}
        hoverColor={hoverColor || null}
        size={size || null}
        border={border || null}
        bgcolor={bgcolor || null}
        fontWeight={fontWeight || null}
        borderRadius={borderRadius || null}
      >
        {element}
      </Root>
    );
  };
export default ButtonComponent;
