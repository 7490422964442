import styled, { media } from '../../styled-components';

export const Root = styled.div`
  padding: 0 24px;
  left: 0;
  right: 0;
  background: #ffffff;
  displlay: flex;
  align-items: center;
  ${media.mobile`
    padding: 0 12px;
  `};

  & > div {
    min-height: 100%;
  }
`;
