import { smartTrim } from '../../../cms/utils';
import {
  Cover,
  Description,
  Informations,
  Logo,
  Name,
  Plus,
  Root
} from './CardContentSimple1Style';
import defaultLogo from './LogoPlaceholder.jpg';
// use this just for startups
const StartupCard = (props: { slug: string; logo?: string; title: string; subtitle: string }) => (
  <Root className="Startup-boxshadow">
    <Cover>
      <a href={props.slug} target="_blank">
        <Logo src={props.logo ? `${props.logo}` : defaultLogo} />
      </a>
    </Cover>
    <a href={props.slug} target="_blank">
      <Informations>
        <Name className="Startup-nom">{props.title}</Name>
        <Description className="Startup-description">{smartTrim(props.subtitle, 140)}</Description>
        <Plus>
          <i className="far fa-plus-circle Jaune-Hub-Icon1" />
        </Plus>
      </Informations>
    </a>
  </Root>
);

export default StartupCard;
