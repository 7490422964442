import React from 'react';
import { Titre3 } from '../ContentImgRightAndButtons/Style';
import {
  Card,
  CardWrapper,
  Name,
  RootCard,
  CarouselWrapper
} from './SectionPreviewListContentsMediumStyle';
import { getElements } from '../../../cms/utils';
import { Carousel, Row, Col } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

type OuterProps = {
  // tslint:disable-next-line:no-any
  sectionData: any;
};
type InnerProps = OuterProps;

const Template: React.FC<InnerProps> = (props: InnerProps) => {
  const { sectionData } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 599px)' });
  const isTablet2 = useMediaQuery({ query: '(min-width: 600px) and (max-width: 769px)' });
  const isTablet3 = useMediaQuery({ query: '(min-width: 772px) and (max-width: 821px)' });
  if (sectionData.data.length === 0 || sectionData.section.length === 0) {
    return null;
  }
  const { data } = sectionData;
  const aImgs = getElements(data[0]);
  // tslint:disable-next-line:no-any
  const aListe: any = [];
  for (let r: number = 0; r < aImgs?.length / 2; r++) {
    let row = aImgs[r * 2];
    let row1 = aImgs[r * 2 + 1];
    aListe.push(
      <a href={`successStory/${row.description}&${row1.description}`}>
        <CardWrapper key={row.id}>
          <Card className="Startup-boxshadow">
            <div
              style={{
                display: 'flex',
                gap: '24px'
              }}
            >
              <div
                style={{
                  height: isTablet2 || isTablet3 ? '90px' : '100px',
                  width: isTablet2 || isTablet3 ? '90px' : '100px',
                  borderRadius: '8px',
                  border: '1px solid var(--wireframe-w-100, #E2E4E9)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden'
                }}
              >
                <img
                  src={row.logo}
                  alt=""
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain'
                  }}
                />
              </div>

              <div
                style={{
                  height: isTablet2 || isTablet3 ? '90px' : '100px',
                  width: isTablet2 || isTablet3 ? '90px' : '100px',
                  borderRadius: '8px',
                  border: '1px solid var(--wireframe-w-100, #E2E4E9)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  marginRight: isTablet2 || isTablet3 ? '8px' : ''
                }}
              >
                <img
                  src={row1.logo}
                  alt=""
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain'
                  }}
                />
              </div>
            </div>

            <Name className="H6-charte">
              {row.description} x {row1.description}
            </Name>
          </Card>
        </CardWrapper>
      </a>
    );
  }
  return (
    <Row>
      <Col lg={{ order: 1, span: 19 }} xs={{ order: 1, span: 24 }}>
        <Titre3>{data[0].title}</Titre3>
      </Col>
      <Col lg={{ order: 3, span: 24 }} xs={{ order: 2, span: 24 }} md={{ order: 3, span: 24 }}>
        <CarouselWrapper>
          <Carousel
            slidesToScroll={isMobile ? 1 : Math.floor(aImgs?.length / 3)}
            arrows={true}
            slidesToShow={isMobile ? 1 : 3}
            dots={true}
            prevArrow={<LeftOutlined data-testid="prevArrow" />}
            nextArrow={<RightOutlined data-testid="nextArrow" />}
          >
            {aListe.map((v: any) => (
              <RootCard key={v.id}>{v}</RootCard>
            ))}
          </Carousel>
        </CarouselWrapper>
      </Col>
    </Row>
  );
};

export default Template;
