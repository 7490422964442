import * as React from 'react';
import ButtonYellow1 from './Buttons/ButtonYellow1';
const getTags = ({ cmstags }: any) => {
  const tags: any = cmstags?.map((tag: any, index: number) => {
    return (
      <div key={index}>
        <ButtonYellow1
          size="11"
          color="#4F4F4F"
          fontWeight="600px"
          bgcolor="white"
          border="solid 1px #FFCD00"
          href={`/tag/${tag.slug}`}
        >
          <span lang="fr" style={{ textTransform: 'uppercase' }}>
            {' '}
            {tag.name}{' '}
          </span>
        </ButtonYellow1>
      </div>
    );
  });
  return tags;
};

export default getTags;
