import styled from '../../../styled-components';
import { media } from 'src/styled-components';
export interface StyledProps {
  width?: String;
  align?: String;
  bgcolor?: String;
  color?: String;
  height?: String;
  hoverColor?: String;
  border?: String;
  size?: string;
  fontWeight?: string;
  borderRadius?: string;
}

export const Root = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  width: max-content;
  ${media.mobile`
  padding: 4px 24px 0px 24px;
  `};
  ${(props) => {
    let ccsExtra: string = '';
    ccsExtra =
      ccsExtra +
      `
      background: ${props.bgcolor ? props.bgcolor : '#FFCD00'};
      `;
    ccsExtra =
      ccsExtra +
      ` 
      &:hover {
        background: ${props.hoverColor ? props.hoverColor : ''}; 
      }
      & > a {
        color: ${props.color ? props.color : '#071A39'};
        text-decoration: none;
        align-items:center;
        display:flex;
        justify-content:center;
    
        &:hover {
          text-decoration: none;
        }
      }`;
    if (props.width) {
      ccsExtra =
        ccsExtra +
        `
      width: ${props.width};
      `;
    } else {
      ccsExtra =
        ccsExtra +
        `
      padding: 0px 24px 0px 24px;
      `;
    }
    if (props.fontWeight) {
      ccsExtra =
        ccsExtra +
        `
      fontWeight: ${props.fontWeight};
      `;
    }

    if (props.size) {
      const lineHeight: number = parseInt(props.size, 10) * 2.5;
      ccsExtra =
        ccsExtra +
        `
      font-size: ${props.size}px;
      line-height: ${lineHeight.toString()}px;
      `;
    } else {
      ccsExtra =
        ccsExtra +
        `
      font-size: 14px;
      line-height: 18px;
        `;
      ccsExtra =
        ccsExtra +
        `  
        height: ${props.height ? props.height : '38px;'};
        `;
    }

    ccsExtra =
      ccsExtra +
      `  
    border-radius: ${props.borderRadius ? props.borderRadius : '20px;'};
    `;

    ccsExtra =
      ccsExtra +
      `  
    border: ${props.border ? props.border : 'auto'};
    `;

    /* if (props.align === 'right') {
      ccsExtra = ccsExtra + `

      `;
    }*/
    return ccsExtra;
  }};
`;
