import styled, { media } from '../../styled-components';

export const ContainerContent = styled.div`
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #222222;
  background-color: #ffffff;
  padding: 0px 30px 30px 30px;

  @media screen and (max-width: 1300px) {
    display: auto;
    width: 100%;
    padding: 5px;
  }

  @media screen and ((max-width: 767px)) {
    display: auto;
    width: 100%;
    padding: 5px;
  }
`;

export const ContainerBarContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 20px 0px 10px 0px;

  ${media.mobile`
grid-area: 2 / 1 / 2 / 4;
  display: grid;

`}
`;

export const IconsBar = styled.div`
  width: 70px;
  margin-bottom: 10px;
  @media screen and (max-width: 1300px) {
    display: block;
    width: 100%;
  }

  ${media.mobile`
  grid-area: 2 / 1 / 2 / 4;
  align-items: center;
  text-align: center;
`}
  float: right;
`;

export const Tags1 = styled.div`
  & span > a {
    color: white;
    white-space: nowrap;
  }

  & > span:last-child > span {
    display: none;
  }
  opacity: 1;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0px;
  margin-top: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  color: white;
  -webkit-box-orient: vertical;
  font-weight: 800;
  text-transform: uppercase;
  font-family: Montserrat, Sans-serif;
  -webkit-box-sizing: border-box;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
`;
export const NavigationWrapper = styled.div`
  max-width: 1140px;
  ${media.desktop`
  margin: 0 auto 0;
`}
  ${media.mobile`
  margin-top:75px;
  font-size: 12px;
`}
`;
export const Name = styled.h1`
  margin-top: 25px;
  margin-bottom: 15px;
  max-width: 900px;
  letter-spacing: -0.2px;
  font-size: 42px;
  font-weight: 900;
  font-family: Montserrat, Sans-serif;
  text-align: center;
  color: white;
  mix-blend-mode: difference;
  background-clip: text;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 2;
  -webkit-box-sizing: border-box;
  line-height: 1.1em;

  ${media.mobile`
  font-size: 28px;
  font-weight: 800;
  -webkit-box-sizing: none;
  letter-spacing: -0.3px;
  text-align: center;
`}
`;

export const RootPage = styled.div`
  justify-content: center;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
`;

export const ImageContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -10px;

  ${media.mobile`
  margin-top: 40px;
  top:0;
  left: 0;
  transform: translate(0%, 0%);
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
`}
`;

export const TextContent = styled.div`
  max-width: 1300px;
  margin-top: 100px;

  ${media.mobile`
  max-width: 350px;
margin-top: 100px;
display: grid;
grid: auto-flow / 1fr 1fr 1fr;
`}
`;

export const TextContent1 = styled.div`
  width: 900px;
  overflow: hidden;

  ${media.mobile`
  max-width: 350px;
  overflow: hidden;
`}
`;
export const Editor = styled.div`
  max-width: 900px;
  color: #444444;
  margin-top: 50px;

  ${media.mobile`
  max-width: 350px;
display: block;

.ql-video {
  width: 300px;
  height: 200px;
  margin: 0 auto;
}

img {
  width: 300px;
  height: 200px;
  margin: 0 auto;
}

`}
`;
export const Share = styled.div`
  display: grid;
  grid-column-gap: var(--grid-column-gap);
  grid-row-gap: var(--grid-row-gap);
  grid-gap: 10px;

  a {
    display: block;
    text-align: center;
    float: left;
    width: 45px;
    height: 46px;
    background-color: #000000;
    border-radius: 100%;
    margin-right: 14px;
    font-size: 10px;
    transition-duration: 0.2s;
    transition-property: filter, background-color, border-color;
    cursor: pointer;
  }

  a:hover {
    display: block;
    text-align: center;
    float: left;
    width: 44px;
    background-color: #fcbf07;
    border-radius: 100%;
    margin-right: 14px;
    font-size: 10px;
    transition-duration: 0.2s;
    transition-property: filter, background-color, border-color;
    height: 4.5em;
    cursor: pointer;
  }
  a i {
    font-size: 17px;
    line-height: 45px;
    color: #ffffff;
  }
  ${media.mobile`
  display: inline-block;
   justify-items:center;
 `}
`;

export const BackgroundImage1 = styled.div`
  width: 100%;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  background-position: center;
  background-color: transparent;
  left: 0;
  right: 0;
  opacity: 0.9;
`;
export const ShareText = styled.div`
  margin-bottom: 20px;
  font-size: 15px;
  font-family: Montserrat, Sans-serif;
  text-align: center;
  font-weight: 600;
  color: #3a3a3a;

  ${media.mobile`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
 `}
`;
