import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import * as React from 'react';
import { flowRight as compose } from 'lodash';
import Helmet from 'react-helmet';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ButtonYellow1 from '../../cms/templates/Buttons/ButtonYellow1';
import {
  Card,
  CardWrapper,
  ContainerWidthReducedCenterCard,
  RootCard
} from '../../cms/templates/Cards/Card2xCol';
import StartupCard from '../../cms/templates/Cards/CardContentSimple1';
import ListContents1Big2x2Medium from '../../cms/templates/ListContents-1Big2x2Medium/ListContents-1Big2x2Medium';
import SectionPreviewListContentsMedium from '../../cms/templates/SectionPreviewListContentsMedium/SectionPreviewListContentsMedium';
import Tags from '../../cms/templates/Tags';
import { processDataContent, processDataTemplate } from '../../cms/utils';
import { fetchQl, pathImg } from '../../utils/common';
import {
  ContainerBarContent,
  ContainerContent,
  IconsBar,
  Tags1,
  NavigationWrapper,
  Name,
  RootPage,
  ImageContent,
  TextContent,
  TextContent1,
  Editor,
  Share,
  BackgroundImage1,
  ShareText
} from './Style';
import 'react-quill/dist/quill.core.css';
import { Description, Informations, Root, Title } from './NotFoundStyle';
import { LinkedinFilled, FacebookFilled, TwitterOutlined, MailFilled } from '@ant-design/icons';
import PageLoader from '../../Components/PageLoader/PageLoader';
const htmlr = require('htmr/lib');

type OuterProps = {
  test?: boolean;
  locationTest: string;
  dataTest: any;
  sectionTest: any;
  taglistTest: any;
  listContentFoundTest: any;
  loadingTest: boolean;
};

type Props = OuterProps & WithTranslation & RouteComponentProps<{}>;

type State = {
  contents: any;
  section: any;
  taglist: any;
  listContentFound: any;
  loading: boolean;
};

class CmsRouter extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      contents: null,
      section: null,
      listContentFound: null,
      loading: true,
      taglist: null
    };
  }
  getUrl = () => {
    return document.location.href;
  };
  async componentDidMount() {
    const path = this.props.test ? this.props.locationTest : location?.pathname;
    const division = path.lastIndexOf('/');
    let sectionFound: any;
    let contentFound: any;
    let tagFound: any;
    let taglist: any = null;
    let actualitesFound: boolean = false;

    if (division > -1) {
      let slugSection: string = '';
      let slugUnknown: string = '';
      slugUnknown = path.substring(1);

      if (slugUnknown === 'actualites') {
        /*
         *  all contents ( actualités)
         * */

        actualitesFound = true;

        const result = await fetchQl(
          `
        query CmsContenuList(
          $page: Int!
          $published: Boolean
          $order: String
        ) {
          cmsContents(
            page: $page
            published: $published
            order: $order
          ) {
            total
            tagsearched
            taglist
            tagsresume
            nodes {
              id
              name
              slug
              subtitle
              content
              published
              startups
              logo {
               id
               location
              }
              cmsAuthor {
                name
                logo {
                  id
                  location
                }
                url
                slug
                content
              }
              cmstags
              date
              createdAt
            }
          }
        }
        `,
          { page: 1, published: true, order: 'updated' }
        );
        if (result && result.data && result.data.cmsContents && result.data.cmsContents.nodes) {
          taglist = result.data.cmsContents.taglist;
          contentFound = processDataContent(result.data.cmsContents.nodes).data;
          sectionFound = {
            name: 'Actualités',
            ref: 'allcontents',
            tagsresume: result.data.cmsContents.tagsresume
          };
        } else {
          // when there is slug contents
        }
      }

      if (!actualitesFound && slugUnknown.substr(0, 4) === 'tag/') {
        const result = await fetchQl(
          `query CmsContenuList(
          $page: Int!
          $searchtagslug: String
        ) {
          cmsContents(
            page: $page
            searchtagslug: $searchtagslug
          ) {
            total
            tagsearched
            taglist
            nodes {
              id
              name
              slug
              subtitle
              content
              published
              startups
              logo {
               id
               location
              }
              cmsAuthor {
                name
                logo {
                  id
                  location
                }
                url
                slug
                content
              }
              cmstags
              date
              createdAt
            }
          }
        }
        `,
          { page: 1, searchtagslug: `${slugUnknown.substr(4)}` }
        );
        if (
          result &&
          result.data &&
          result.data.cmsContents &&
          result.data.cmsContents.tagsearched &&
          result.data.cmsContents.nodes
        ) {
          taglist = result.data.cmsContents.taglist;
          tagFound = true;
          contentFound = processDataContent(result.data.cmsContents.nodes).data;
          sectionFound = {
            name: 'Tag: ' + result.data.cmsContents.tagsearched,
            subtitle: result.data.cmsContents.tagsearched
          };
        } else {
          // when there is slug contents
        }
      }
      if (!tagFound) {
        // actualites is not found / tag nout found/ then look for slug tag:
        const finalSlugSection = slugSection
          ? slugSection.substring(slugSection.lastIndexOf('/') + 1)
          : '';
        const finalSlugUnknown = slugUnknown.substring(slugUnknown.lastIndexOf('/') + 1);
        const resultT = await fetchQl(
          `
      query CmsTemplateList ($searchslug: String )  {
        cmsTemplates (searchslug: $searchslug){
          id
          name
          description
          template
          section
          path
          content
        }
      }
      `,
          { searchslug: `${slugSection ? finalSlugSection : finalSlugUnknown}` }
        );
        if (
          resultT &&
          resultT.data &&
          resultT.data.cmsTemplates &&
          resultT.data.cmsTemplates &&
          resultT.data.cmsTemplates.length > 0
        ) {
          sectionFound = resultT.data.cmsTemplates[0];
          const result = await fetchQl(
            `
        query CmsSectionContentList($page: Int!, $templateId: Int) {
          cmsSectionContents(page: $page, templateId: $templateId) {
            totalCount
            nodes {
              id
              startup {
                id
                name
                description
                slug
                logo {
                  id
                  location
                }
              }
              cmscontent {
                id
                name
                slug
                subtitle
                logo {
                  id
                  location
                }
              }
              cmssection {
                id
                name
                path
                description
                logo {
                  id
                  location
                }
              }
              author {
                firstName
                lastName
              }
              joinedDate
              position
            }
          }
        }
        `,
            { page: 1, templateId: sectionFound.id }
          );
          if (
            result &&
            result.data &&
            result.data.cmsSectionContents &&
            result.data.cmsSectionContents.nodes
          ) {
            contentFound = processDataTemplate(result.data.cmsSectionContents.nodes).data;
          } else {
            // when there is slug contents
          }
        }
      }
      if (!sectionFound && path.includes('/actualites/')) {
        // there is slug for contents and slug for section was not found
        const finalSlugSection = slugSection
          ? slugSection.substring(slugSection.lastIndexOf('/') + 1)
          : '';
        const finalSlugUnknown = slugUnknown.substring(slugUnknown.lastIndexOf('/') + 1);
        const result = await fetchQl(
          `
        query CmsContenuList(
          $page: Int!
          $searchslug: String
        ) {
          cmsContents(
            page: $page
            searchslug: $searchslug
          ) {
            total
            nodes {
              id
              name
              slug
              subtitle
              content
              published
              startups
              logo {
               id
               location
              }
              cmsAuthor {
                name
                logo {
                  id
                  location
                }
                url
                slug
                content
              }
              cmstags
              relatedcontent
              date
              createdAt
            }
          }
        }
        `,
          {
            page: 1,
            searchslug: `${
              slugSection
                ? decodeURIComponent(finalSlugSection)
                : decodeURIComponent(finalSlugUnknown)
            }`
          }
        );
        if (
          result &&
          result.data &&
          result.data.cmsContents &&
          result.data.cmsContents.nodes &&
          result.data.cmsContents.nodes.length === 1
        ) {
          contentFound = result.data.cmsContents.nodes[0];
        } else {
          // when there is slug contents
        }
      }

      if (this.props.test) {
        this.setState({
          contents: this.props.dataTest,
          section: this.props.sectionTest,
          taglist: this.props.taglistTest,
          loading: this.props.loadingTest
        });
      }
      this.props.test ??
        this.setState({ contents: contentFound, section: sectionFound, taglist, loading: false });
    }
  }

  render() {
    const { contents, section, loading } = this.state;
    const { t } = this.props;
    if (loading) {
      return <PageLoader />;
    }
    if (section && section.ref === 'allcontents') {
      return (
        <div className="RootPage" style={{ alignItems: 'center' }}>
          <Helmet>
            <title>
              {t('pageactualites.title', {
                ns: 'seo'
              })}
            </title>
            <meta
              name="description"
              content={t('pageactualites.description', {
                ns: 'seo'
              })}
              data-react-helmet="true"
            />
          </Helmet>

          <div style={{ maxWidth: '1300px' }}>
            <ContainerContent>
              <div style={{ marginTop: '50px', alignItems: 'center' }}>
                <h1 className="H1-charte" style={{ marginBottom: '49px' }}>
                  Actualités
                </h1>
                <ListContents1Big2x2Medium sectionData={{ data: contents, section }} />
              </div>
            </ContainerContent>
          </div>
        </div>
      );
    } else if (section) {
      // sections that contains sections , startups or contents, when contain startup a special display card is shown
      // page tags
      const aListe: any = [];

      if (contents.length > 0) {
        if (contents[0].type === 'startup') {
          const aListeStartups: any = [];

          for (let r: number = 0; r < contents.length; r++) {
            let row = contents[r];
            aListeStartups.push(
              <CardWrapper key={row.id}>
                <Card>
                  <StartupCard {...row} />
                </Card>
              </CardWrapper>
            );
          }
          aListe.push(
            <CardWrapper style={{ maxWidth: '800px' }} key={aListe.length}>
              {aListeStartups}
            </CardWrapper>
          );
        } else {
          contents.map((row: any) => {
            aListe.push(
              <div
                key={row.id}
                className="Startup-boxshadow"
                style={{ display: 'flex', margin: '20px 5px 30px 5px', padding: '20px' }}
              >
                <div style={{ width: '25%', padding: '0px 25px 0px 0px' }}>
                  {row.logo && (
                    <a href={`actualites${row.slug}`} aria-label={`actualites${row.slug}-logo`}>
                      <div
                        className="cCntImage2"
                        style={{
                          width: '100%',
                          minHeight: '150px',
                          background: row.logo
                            ? `url('${row.logo}') no-repeat center/contain`
                            : undefined,
                          backgroundSize: 'cover'
                        }}
                      />
                    </a>
                  )}
                </div>
                <div style={{ width: '75%' }}>
                  <div className="H4-charte">
                    <a href={`actualites${row.slug}`}>{row.title}</a>
                  </div>
                  <div className="cSubtitle2">{row.subtitle} </div>
                  <div className="Data1" style={{ marginBottom: '20px', marginTop: '20px' }}>
                    {row.author && <span>{row.author}&nbsp;&nbsp;&nbsp;</span>} {row.updated}
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    <Tags cmstags={row.cmstags} />
                  </div>
                </div>
              </div>
            );
          });
        }
      }

      return (
        <div className="RootPage" style={{ alignItems: 'center' }}>
          <Helmet>
            <title>{section.subtitle + ': Actualités Et Tendances '}</title>
            <meta
              name="description"
              content={
                section.subtitle +
                " ➤ Toute l’actualité ✅ tendance pour tout comprendre sur l'univers 🚀" +
                section.subtitle
              }
              data-react-helmet="true"
            />
            <meta property="og:title" content={section.subtitle + ': Actualités Et Tendances '} />
            <meta
              property="og:description"
              content={
                section.subtitle +
                " ➤ Toute l’actualité ✅ tendance pour tout comprendre sur l'univers 🚀" +
                section.subtitle
              }
              data-react-helmet="true"
            />
            {contents.length > 0 && contents[0].logo && (
              <meta property="og:image" content={`${contents[0].logo}`} />
            )}
          </Helmet>
          <div style={{ maxWidth: '1100px' }}>
            <div className="H1-charte">{section.name}</div>
            <div className="cContent1" style={{ marginBottom: '30px' }}>
              <div
                className="view ql-editor"
                dangerouslySetInnerHTML={{
                  __html: section.content
                }}
              ></div>
            </div>
            {aListe}
          </div>
        </div>
      );
    } else if (contents) {
      /**********************
       *
       *  only one content (page content)
       *
       * */
      const aListe: any = [];
      const listPure: any = contents.startups ? JSON.parse(contents.startups) : [];
      for (let r: number = 0; r < listPure.length; r++) {
        let row = listPure[r];
        aListe.push(
          <CardWrapper key={row.id}>
            <Card>
              <StartupCard {...row} />
            </Card>
          </CardWrapper>
        );
      }
      const cmstags: any[] = contents.cmstags ? JSON.parse(contents.cmstags) : [];
      const aRelated = {
        section: [{ title: 'Et aussi' }],
        data: contents.relatedcontent ? JSON.parse(contents.relatedcontent) : []
      };
      let metaKeywords: string = '';
      cmstags.map((tag: any) => {
        metaKeywords = metaKeywords + (metaKeywords ? `, ` : '') + `${tag.name}`;
      });
      const authorComplete =
        contents.cmsAuthor &&
        contents.cmsAuthor.name &&
        contents.cmsAuthor.slug &&
        contents.cmsAuthor.url &&
        contents.cmsAuthor.content &&
        contents.cmsAuthor.logo;
      let author = {
        name: '',
        slug: '',
        url: '',
        description: '',
        logo: ''
      };
      if (authorComplete) {
        author = {
          name: contents.cmsAuthor.name,
          slug: contents.cmsAuthor.slug,
          url: contents.cmsAuthor.url,
          description: contents.cmsAuthor.content,
          logo: `${pathImg}/${contents.cmsAuthor.logo.location}`
        };
      }
      return (
        <div
          style={{
            padding: '0 0px 0px',
            marginTop: 10
          }}
        >
          <Helmet>
            <title>{contents.name}</title>
            <meta
              name="description"
              content={contents?.subtitle?.replace(/^([\s\S]{150}[^\s]*)[\s\S]*/, '$1')}
              data-react-helmet="true"
            />
            <meta name="keywords" content={metaKeywords} data-react-helmet="true" />
            <meta property="og:title" content={contents.name} />
            <meta property="og:description" content={contents.subtitle} />
            {contents.logo && contents.logo.location && (
              <meta property="og:image" content={`${pathImg}/${contents.logo.location}`} />
            )}
          </Helmet>

          <NavigationWrapper className="Navigation">
            <a href="/">Accueil</a>
            <a href="/actualites">Actualités</a>
            <a> {contents.name}</a>
          </NavigationWrapper>

          <RootPage>
            <div style={{ width: '100%', margin: '0px 110px 0px 0px', marginBottom: '300px' }}>
              {contents.logo && (
                <BackgroundImage1
                  data-testid="logo-contents"
                  style={{
                    height: contents.logo ? '400px' : undefined,
                    backgroundImage: contents.logo
                      ? `radial-gradient(farthest-side,rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.1)),url('${pathImg}/${contents.logo.location}')`
                      : undefined
                  }}
                >
                  <ImageContent>
                    <Tags1 data-testid="tags-contents">
                      {cmstags.map((tag: any, index) => (
                        <span key={tag.id}>
                          <a href={`/tag/${tag.slug}`}>{tag.name}</a>
                          <span>, </span>
                        </span>
                      ))}
                    </Tags1>

                    <Name data-testid="h1-contents">{contents.name}</Name>
                    <div
                      data-testid="date-contents"
                      style={{
                        marginBottom: '30px',
                        color: 'white',
                        textAlign: 'center',
                        fontSize: '13px',
                        fontWeight: 800,
                        fontFamily: 'Montserrat , Sans-serif',
                        backgroundClip: 'text',
                        textShadow: '0 0 10px rgba(0,0,0,0.25)',
                        zIndex: 2,
                        WebkitBoxSizing: 'border-box',
                        marginTop: '20px',
                        textTransform: 'uppercase',
                        position: 'relative',
                        lineHeight: '21px'
                      }}
                    >
                      {contents.date
                        ? format(parseISO(contents.date), 'dd LLLL yyyy', { locale: fr })
                        : ''}{' '}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {contents.cmsAuthor && (
                        <span>{contents.cmsAuthor.name}&nbsp;&nbsp;&nbsp;</span>
                      )}
                    </div>
                  </ImageContent>
                </BackgroundImage1>
              )}
            </div>

            <TextContent>
              <ContainerBarContent>
                <IconsBar data-testid="iconsBar-contents">
                  <ShareText>Partager sur</ShareText>
                  <Share>
                    <a
                      target="_blank"
                      title="Facebook"
                      href={`https://www.facebook.com/sharer.php?u=${this.getUrl()}`}
                      rel="nofollow"
                    >
                      <i>
                        <FacebookFilled style={{ color: 'white' }} />
                      </i>
                    </a>
                    <a
                      target="_blank"
                      href={`https://twitter.com/share?url=${this.getUrl()}`}
                      rel="nofollow"
                      title="Twitter"
                    >
                      <i>
                        <TwitterOutlined style={{ color: 'white' }} />
                      </i>
                    </a>
                    <a
                      target="_blank"
                      href={`https://www.linkedin.com/shareArticle?mini=true&url=${this.getUrl()}`}
                      rel="nofollow"
                      title="Linkedin"
                    >
                      <i>
                        <LinkedinFilled style={{ color: 'white' }} />
                      </i>
                    </a>
                    <a
                      href={`mailto:"mailto:?subject=lehub digital;body=${this.getUrl()}`}
                      title="Mailto"
                    >
                      <i>
                        <MailFilled style={{ color: 'white' }} />
                      </i>
                    </a>
                  </Share>
                </IconsBar>

                <TextContent1>
                  <div
                    data-testid="cmstags-contents"
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      maxWidth: 'unset',
                      float: 'right',
                      right: 0,
                      overflow: 'hidden',
                      gap: '7px'
                    }}
                  >
                    {cmstags.map((tag: any, index) => {
                      return (
                        <div style={{ marginRight: '10px' }} key={tag.id}>
                          <ButtonYellow1
                            size="11"
                            bgcolor="white"
                            border="solid 2px #FFCD00"
                            href={`/tag/${tag.slug}`}
                            color="#737373"
                          >
                            {tag.name}
                          </ButtonYellow1>
                        </div>
                      );
                    })}
                  </div>
                  <Editor className="cContent1">
                    <div
                      className="view ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: contents.subtitle
                      }}
                    ></div>
                    <div
                      className="view ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: contents.content
                      }}
                    ></div>
                  </Editor>
                </TextContent1>
              </ContainerBarContent>
            </TextContent>
            {aListe.length > 0 && (
              <ContainerWidthReducedCenterCard
                style={{ marginTop: '0px', paddingTop: '40px', paddingBottom: '-10px' }}
              >
                <RootCard>{aListe}</RootCard>
              </ContainerWidthReducedCenterCard>
            )}
            <div style={{ marginTop: '50px', display: 'flex', alignItems: 'center' }}>
              <SectionPreviewListContentsMedium sectionData={aRelated} />
            </div>
            {authorComplete && author && (
              <div style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', padding: 20, border: '1px solid #eee' }}>
                  <div style={{ marginRight: 20, padding: 20 }}>
                    <a href={author.url}>
                      <img
                        src={author.logo}
                        alt=""
                        style={{ borderRadius: '50%', width: 150, height: 150 }}
                      />
                    </a>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
                    <div>
                      <a href={author.url} rel="author">
                        <span className="H5-charte">{author.name}</span>
                      </a>
                    </div>
                    {htmlr(author.description)}
                    <div style={{ marginTop: 10, lineHeight: '1 !important' }}>
                      <a target="_self" href={author.url} rel="nofollow">
                        <svg
                          aria-hidden="true"
                          style={{ width: 18, height: 18 }}
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="#aaa"
                            d="M100.3 480H7.4V180.9h92.9V480zM53.8 140.1C24.1 140.1 0 115.5 0 85.8 0 56.1 24.1 32 53.8 32c29.7 0 53.8 24.1 53.8 53.8 0 29.7-24.1 54.3-53.8 54.3zM448 480h-92.7V334.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V480h-92.8V180.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V480z"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </RootPage>
        </div>
      );
    } else
      return (
        <Root>
          <Helmet>
            <Trans t={t} i18nKey="notFound.pageTitle">
              <title>Page introuvable</title>
            </Trans>
          </Helmet>
          <Informations>
            <Title>
              <Trans t={t} i18nKey="notFound.header">
                Mmh... Il semble y avoir une petite erreur.
              </Trans>
            </Title>
            <Description>
              <Trans t={t} i18nKey="notFound.description">
                La ressource à laquelle vous vous attendiez n’a pas l’air de se trouver ici, nous
                vous recommandons de revenir à l’accueil pour, a minima, retrouver votre chemin.
              </Trans>
            </Description>
          </Informations>
        </Root>
      );
  }
}

export default compose(withTranslation(['errors', 'seo']))(withRouter(CmsRouter));
