import * as React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'src/DesignSystem/Button/Button';
import {
  BlockGray,
  LinkWhite,
  LinkWhite1,
  Logo,
  PanelTitle,
  Root,
  TitleSection,
  Wrapper,
  Intro,
  StyledCollapse
} from './FooterStyle';
import { Collapse, Row, Col } from 'antd';
import { logError } from 'src/utils/errorManagement';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
type State = {};
class Footer extends React.PureComponent<WithTranslation, State> {
  constructor(props: WithTranslation) {
    super(props);
    this.state = {};
  }

  clientWidth = function () {
    return Math.max(window.innerWidth, document.documentElement.clientWidth);
  };

  renderLinks() {
    const { t } = this.props;
    return (
      <LinkWhite>
        <a href={`mailto:lehub.digital@bpifrance.fr`} target="_blank">
          <Trans t={t} i18nKey="footer.contactus">
            footer.contactus
          </Trans>
        </a>
      </LinkWhite>
    );
  }

  renderLinks1() {
    const { t } = this.props;
    return (
      <>
        <LinkWhite>
          <a href="https://intercom.help/bpifrance-le-hub" target="_blank">
            <Trans t={t} i18nKey="footer.faq">
              FAQ
            </Trans>
          </a>
        </LinkWhite>
        <LinkWhite>
          <Link to="/partners">
            <Trans t={t} i18nKey="footer.parteners">
              Nos partenaires
            </Trans>{' '}
          </Link>
        </LinkWhite>
        <LinkWhite>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              try {
                if (window.showPrivacyCenter) {
                  window.showPrivacyCenter();
                }
              } catch (error) {
                logError(error);
              }
            }}
          >
            Gestion des cookies
          </a>
        </LinkWhite>
      </>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <Root>
        <Wrapper>
          <BlockGray>
            <PanelTitle>
              <div style={{ margin: '0px' }}>
                <Link to="/" aria-label="footer logo">
                  <Logo alt="footer logo" />
                </Link>
              </div>
            </PanelTitle>
            <Intro style={{ margin: '20px 20px 0px 0px' }} className="Intro1">
              <Trans t={t} i18nKey="footer.startup.subtitle">
                footer.startup.subtitle
              </Trans>
            </Intro>

            <div style={{ margin: '16px 0px 10px 0px' }}>
              <Button
                kind="secondary"
                to={'/startups'}
                style={{
                  overflow: 'hidden',
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontFamily: 'IBM Plex Sans',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  textAlign: 'center',
                  width: `139px`,
                  border: '1px solid #001d4a',
                  height: '32px',
                  fontFeatureSettings: `'clig' off, 'liga' off`
                }}
              >
                <Trans t={t} i18nKey="footer.startup.savoirplus">
                  footer.startup.savoirplus
                </Trans>
              </Button>
            </div>
          </BlockGray>

          {this.clientWidth() < 600 ? (
            <Collapse
              expandIconPosition={'end'}
              style={{
                width: '100%'
              }}
              bordered={false}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <UpOutlined style={{ color: 'black' }} />
                ) : (
                  <DownOutlined style={{ color: 'black' }} />
                )
              }
            >
              <StyledCollapse
                header={
                  <TitleSection>
                    <Trans t={t} i18nKey="footer.section1.title">
                      footer.section1.title
                    </Trans>
                  </TitleSection>
                }
                key="1"
              >
                {this.renderLinks1()}
              </StyledCollapse>
              <StyledCollapse
                header={
                  <TitleSection>
                    <Trans t={t} i18nKey="footer.section2.title">
                      footer.section2.title
                    </Trans>
                  </TitleSection>
                }
                key="2"
              >
                {this.renderLinks()}
              </StyledCollapse>
            </Collapse>
          ) : (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ margin: '0px 0px 30px 30px', minWidth: '420px' }}>
                <TitleSection>
                  <Trans t={t} i18nKey="footer.section1.title">
                    footer.section1.title
                  </Trans>
                </TitleSection>
                {this.renderLinks1()}
              </div>
              <div style={{ minWidth: '200px' }}>
                <TitleSection>
                  <Trans t={t} i18nKey="footer.section2.title">
                    footer.section2.title
                  </Trans>
                </TitleSection>

                {this.renderLinks()}
              </div>
            </div>
          )}
        </Wrapper>
        <Row
          style={{
            borderTop: '1px solid var(--Gris-200, #D3D3D3)',
            background: 'white'
          }}
        >
          <Col xs={{ span: 24 }} md={{ span: 5, offset: 0 }}>
            <LinkWhite1>
              <Link to="/legal">
                <Trans t={t} i18nKey="footer.legal">
                  Mentions légales
                </Trans>
              </Link>
            </LinkWhite1>
          </Col>
          <Col xs={{ span: 24 }} md={4}></Col>

          <Col xs={{ span: 24 }} md={4}>
            <LinkWhite1>
              <Link to="/cgu">
                <Trans t={t} i18nKey="footer.cgu">
                  CGU
                </Trans>
              </Link>
            </LinkWhite1>
          </Col>
          <Col xs={{ span: 24 }} md={4}></Col>
          <Col xs={{ span: 24 }} md={{ span: 3 }}>
            <span
              style={{
                color: 'var(--Bleu-500, #001D4A)',
                fontFamily: 'IBM Plex Sans',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                margin: '15px 0px 0px 0px',
                display: 'inline-block'
              }}
            >
              <Trans t={t} i18nKey="footer.copyright">
                2024 © Copyright Bpifrance
              </Trans>
            </span>
          </Col>
        </Row>
      </Root>
    );
  }
}

export default withTranslation('generic')(Footer);
