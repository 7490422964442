import React, { useState } from 'react';
import { getElements } from '../../cms/utils';
import { Row, Col, Pagination, Select } from 'antd';
import { PaginationWrapper } from './SuccessStoriesStyle';
import SuccessStoryCard from './Components/SuccessStoryCard';
type Props = {
  sectionData: any;
};
const Template: React.FC<Props> = (props: Props) => {
  const [pageSize, setPageSize] = useState<number>(6);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { sectionData } = props;
  if (sectionData.data.length === 0 || sectionData.section.length === 0) {
    return null;
  }
  const { data } = sectionData;
  const aImgs = getElements(data[0]);
  const aListe: any = [];
  for (let r: number = 0; r < aImgs.length / 2; r++) {
    let row = aImgs[r * 2];
    let row1 = aImgs[r * 2 + 1];
    aListe.push({
      cardimg1: row.logo,
      cardimg2: row1.logo,
      cardTitle: `${row.description} x ${row1.description}`
    });
  }
  const totalCards = aListe.length;
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  };
  const renderCards = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalCards);

    return aListe
      .slice(startIndex, endIndex)
      .map((card: any, cardIndex: any) => (
        <SuccessStoryCard
          key={cardIndex}
          card={card}
          cardIndex={cardIndex}
          startIndex={startIndex}
        />
      ));
  };
  const { Option } = Select;
  return (
    <div style={{ marginTop: 50 }}>
      <Row>
        <Col style={{ width: '100%' }}>
          <h1 style={{ textAlign: 'center' }}>Success Stories</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={{ offset: 3 }} lg={{ offset: 6 }}>
          <Row gutter={[0, 24]} style={{ maxWidth: 1200 }}>
            {renderCards()}
          </Row>
          <Row style={{ marginLeft: 20 }}>
            <Col
              span={24}
              style={{ textAlign: 'right', marginTop: 20 }}
              lg={{ span: 12, offset: 12 }}
            >
              <Row>
                <Col sm={{ span: 24 }} lg={{ span: 8, offset: 6 }} style={{ marginLeft: 20 }}>
                  <Row>
                    <Col>
                      <span
                        style={{
                          color: 'var(--bleu-500, #001D4A)',
                          fontFeatureSettings: "'clig' off, 'liga' off",
                          fontFamily: 'IBM Plex Sans',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          lineHeight: '115%'
                        }}
                      >
                        Entrées par page
                      </span>
                    </Col>
                    <Col lg={{ span: 4, offset: 4 }} sm={{ span: 10, offset: 2 }}>
                      <Select defaultValue={pageSize} onChange={handlePageSizeChange}>
                        <Option value="6">6</Option>
                        <Option value="12">12</Option>
                      </Select>
                    </Col>
                  </Row>
                </Col>
                <Col sm={{ span: 24 }} lg={{ span: 12, offset: 1 }}>
                  <PaginationWrapper>
                    <Pagination
                      total={totalCards}
                      showTotal={(total, range) => {
                        return `${range[0]}-${range[1]} sur ${total} members`;
                      }}
                      defaultPageSize={6}
                      defaultCurrent={1}
                      size="small"
                      style={{ marginTop: 5 }}
                      current={currentPage}
                      onChange={handlePageChange}
                      responsive
                    />
                  </PaginationWrapper>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Template;
