import { Carousel } from 'antd';
import styled, { media } from '../../../styled-components';

export const RootCard = styled.div`
  margin-bottom: 22px;
  margin-top: 20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 900px) {
    width: 100%;
    display: block;
  }
`;

export const SectionTitle = styled.h3`
  color: var(--bleu-500, #001d4a);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: IBM Plex Sans Condensed;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 36.4px */
  margin-left: 4px;
`;

export const PRegular = styled.p`
color: 'var(--bleu-500, #001D4A)',
fontFeatureSettings: "'clig' off, 'liga' off",
fontFamily: 'IBM Plex Sans',
fontSize: '16px',
fontStyle: 'normal',
fontWeight: 400,
lineHeight: '150%',
marginTop: 6
`;

export const CarouselWrapper = styled(Carousel)`
  height: 270px;
  .ant-carousel .slick-dots-bottom {
    bottom: -20px;
  }
  .ant-carousel .slick-dots li button {
    margin-bottom: 100px;
    flex-shrink: 0;
    background: var(--bleu-700, gray);
    opacity: 0.4;
  }

  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: var(--bleu-500, #001d4a);
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover {
    font-size: inherit;
    color: currentColor;
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-prev:hover {
    left: -20px;
    z-index: 2;
    color: black;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
  }

  .ant-carousel .slick-next,
  .ant-carousel .slick-next:hover {
    right: -20px;
    z-index: 2;
    color: black;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
  }
  .ant-carousel .slick-slide {
    width: 312px;
  }

  ${media.mobile`
  & > .ant-carousel .slick-slider .slick-list [aria-hidden='true'] div {
    display: none;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -10px;
  }
  .ant-carousel .slick-dots li button {
    margin-bottom: 100px;
    flex-shrink: 0;
    opacity: 0.4;
    border-radius: 100px;
    background: var(--bleu-700, #1E88E5);
width: 8px;
height: 8px;
flex-shrink: 0;
 
  }
 
  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: var(--bleu-500, #001d4a);
    border-radius: 100px;
  width: 8px;
height: 8px;
flex-shrink: 0;
  }
 
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover {
    font-size: inherit;
    color: currentColor;
  }
 
  .ant-carousel .slick-prev,
  .ant-carousel .slick-prev:hover {
    left: -20px;
    z-index: 2;
    color: black;
    flex-shrink: 0;
    width: 0px;
    height: 0px;
  }
 
  .ant-carousel .slick-next,
  .ant-carousel .slick-next:hover {
    right: -20px;
    z-index: 2;
    color: black;
    flex-shrink: 0;
    width: 0px;
    height: 0px;
  }
`};
`;
