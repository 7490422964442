import { format, parseISO } from 'date-fns';
// import { formatZoned , parseISO, zonedTimeToUtc, utcToZonedTime, } from date-fns-tz
import { fr } from 'date-fns/locale';
import { TemplateReponse } from './types';
import { pathImg } from '../utils/common';

export const smartTrim = (
  str: string,
  length: number,
  delim: string = ' ',
  appendix: string = '...'
) => {
  if (!str || str.length <= length) {
    return str;
  }
  let trimmedStr: string = str.substr(0, length + delim.length);

  const lastDelimIndex = trimmedStr.lastIndexOf(delim);
  if (lastDelimIndex >= 0) {
    trimmedStr = trimmedStr.substr(0, lastDelimIndex);
  }
  if (trimmedStr.length < str.length) {
    trimmedStr = trimmedStr + appendix;
  }
  return trimmedStr;
};

export const encodeForProxy = (str: string) => {
  if (!str) {
    return str;
  }
  let strEncoded: string = str;
  strEncoded = strEncoded.replace(/%/g, '0x25(25)');
  return strEncoded;
};

// tslint:disable-next-line:no-any
const getDataContent = (row: any) => {
  // tslint:disable-next-line:no-any
  let startups: any = row.startups ? JSON.parse(row.startups) : [];
  // tslint:disable-next-line:no-any
  let gallery: any = row.gallery ? JSON.parse(row.gallery) : [];

  /*
  // Set the date to "2018-09-01T16:01:36.386Z"
  const utcDate = zonedTimeToUtc('2018-09-01 18:01:36.386', 'Europe/Berlin')
   // Obtain a Date instance that will render the equivalent Berlin time for the UTC date
    const date = new Date('2018-09-01T16:01:36.386Z')
    const timeZone = 'Europe/Berlin'
    const zonedDate = utcToZonedTime(date, timeZone)
    // zonedDate could be used to initialize a date picker or display the formatted local date/time
    const pattern = 'd.M.yyyy HH:mm:ss.SSS \'GMT\' XXX (z)'
    const output = format(zonedDate, pattern, { timeZone: 'Europe/Berlin' }) */
  return {
    title: row.name,
    pretitle: row.pretitle,
    cmstags: row.cmstags ? JSON.parse(row.cmstags) : [],
    author: row.cmsAuthor ? row.cmsAuthor.name : '',
    updated: row.date ? format(parseISO(row.date), 'dd LLLL yyyy', { locale: fr }) : null,
    slug: '/' + row.slug,
    logo: row.logo && row.logo.location ? `${pathImg}/${row.logo.location}` : '',
    subtitle: row.subtitle,
    content: row.content ? row.content : '',
    type: 'content',
    startups,
    gallery
  };
};
// tslint:disable-next-line:no-any
export const getElements = (data: any, code: string = '', colReturn = ''): any => {
  // tslint:disable-next-line:no-any
  const responseData: any = [];
  // tslint:disable-next-line:no-any
  data.gallery.map((row: any) => {
    if (row && (!row.code || (row.code && row.code.includes(code)))) {
      responseData.push({ ...row, logo: row.logo ? `${pathImg}/${row.logo}` : null });
    }
  });
  if (colReturn) {
    if (responseData.length > 0) {
      if (colReturn === '*') {
        // just one row
        return responseData[0];
      } else {
        return responseData[0][colReturn];
      }
    }
    return null;
  } else {
    return responseData;
  }
};

// tslint:disable-next-line:no-any
export const processDataContent = (data: any, sectionCode: string = ''): any => {
  // tslint:disable-next-line:no-any
  const responseData: any = [];
  // tslint:disable-next-line:no-any
  data.map((row: any, index: number) => {
    if (!sectionCode || sectionCode === row.code) {
      responseData.push(getDataContent(row));
    }
  });
  return { data: responseData };
};

// tslint:disable-next-line:no-any
export const processDataTemplate = (
  data: any,
  sectionCode: string = '',
  type: string = 'data'
): TemplateReponse => {
  // tslint:disable-next-line:no-any
  const responseData: any = [];
  // tslint:disable-next-line:no-any
  let section: any = [];
  // tslint:disable-next-line:no-any
  data.map((row: any, index: number) => {
    if (!sectionCode || sectionCode === row.cmstemplate.section) {
      if ((section.length === 0 && row.cmstemplate && type === 'data') || type === 'section') {
        let srow = {
          title: row.cmstemplate.name,
          subtitle: row.cmstemplate.description,
          pretitle: row.cmstemplate.pretitle,
          updated: null, // format(parseISO(row.cmstemplate.updatedAt), 'PP', { locale: fr }),
          template: row.cmstemplate.template,
          slug: '/' + row.cmstemplate.path,
          logo: row.cmstemplate.logo ? `${pathImg}/${row.cmstemplate.logo.location}` : ''
        };
        section.push(srow);
        // for section, really line before, have no sens, group of section have not really a leader section
        // but it's important send a row for the logic
        if (type === 'section') {
          responseData.push(srow);
        }
      }
      if (type === 'data') {
        // tslint:disable-next-line:no-any
        let partData: any = {};
        if (row.cmssection) {
          partData = {
            title: row.cmssection.name,
            pretitle: row.cmssection.pretitle,
            slug: '/' + row.cmssection.path,
            logo:
              row.cmssection.logo && row.cmssection.logo.location
                ? `${pathImg}/${row.cmssection.logo.location}`
                : '',
            subtitle: row.cmssection.description ? smartTrim(row.cmssection.description, 150) : '',
            type: 'section'
          };
        } else if (row.cmscontent) {
          partData = getDataContent(row.cmscontent);
        } else if (row.startup) {
          partData = {
            title: row.startup.name,
            pretitle: '',
            slug: `/startup/${row.startup.slug}`,
            logo:
              row.startup.logo && row.startup.logo.location
                ? `${pathImg}/${row.startup.logo.location}`
                : '',
            subtitle: row.startup.description ? smartTrim(row.startup.description, 150) : '',
            type: 'startup'
          };
        }

        responseData.push({
          id: row.id,
          startupId: row.startup ? row.startup.id : null,
          contentId: row.cmscontent ? row.cmscontent.id : null,
          sectionId: row.cmsection ? row.cmsection.id : null,
          ...partData,
          content: ''
        });
      }
    }
  });
  return { data: responseData, section };
};
