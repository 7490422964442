import { withRouter, RouteComponentProps } from 'react-router-dom';
/* import { useTranslation } from 'react-i18next'; */
import { ChildProps } from '@apollo/client/react/hoc';
import { Root } from './HeaderTechStyle';
import { HomemainQuery, HomemainQueryVariables } from '../../operation-result-types';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
const HOMEMAIN_QUERY = loader('../../Scenes/Home/Modules/HomeMain/homemain.query.gql');
import { getElements, processDataContent } from '../../cms/utils';

type InnerProps = ChildProps<{}, HomemainQuery> & RouteComponentProps<{}>;

const Header = (props: InnerProps) => {
  /* const { t } = useTranslation(['generic']); */
  /* const [value, setValue] = useState<string>('');
  const [hoverTooltip, setHoverTooltip] = useState<boolean>(false);
  const [tooltipoX, setTooltipoX] = useState<number>(0);
  const [tooltipoY, setTooltipoY] = useState<number>(0); */

  const { loading, /* error, */ data } = useQuery<HomemainQuery, HomemainQueryVariables>(
    HOMEMAIN_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        sections: JSON.stringify([]),
        contentcodes: JSON.stringify(['techinfab']),
        page: 0
      }
    }
  );

  /* const iconSearch = () => {
    props.history.replace(`/search?query=${value}`);
  };

  const search = (value: string) => {
    props.history.push(`/search?query=${value}`);
  }; */

  /* const handleMouseInTooltip = (e: React.MouseEvent<HTMLElement>) => {
    const x = e.clientX;
    const y = e.clientY;
    if (tooltipoX === 0) {
      setHoverTooltip(true);
      setTooltipoX(x - 100);
      setTooltipoY(y + 10);
    }
  }; */

  /* const handleMouseOutTooltip = () => {
    setHoverTooltip(false);
    setTooltipoX(0);
    setTooltipoY(0);
  }; */

  if (!data || loading) {
    return null;
  }
  const dataContent =
    data && data.cmsContents && data.cmsContents.nodes
      ? processDataContent(data.cmsContents.nodes).data
      : [];
  if (dataContent.length === 0) {
    return null;
  }
  const aHeader = getElements(dataContent[0], 'Header', '*') || {};
  return (
    <Root id="header" style={{ height: aHeader.attribute1, alignItems: 'center' }}>
      <a href={aHeader.url}>
        <img src={aHeader.logo} alt="" />
      </a>
    </Root>
  );
};

export default withRouter(Header);
