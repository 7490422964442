import * as styledComponents from 'styled-components';
import { SimpleInterpolation, ThemedStyledComponentsModule } from 'styled-components';
import ThemeInterface from './theme';

const {
  default: styled,
  css,
  keyframes,
  ThemeProvider
} = styledComponents as ThemedStyledComponentsModule<ThemeInterface>;

export { css, keyframes, ThemeProvider };
export default styled;

export const media = {
  // for phone only
  mobile: (strings: TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => css`
    @media (max-width: 599px) {
      ${css(strings, ...interpolations)};
    }
  `,
  // for tablet portrait and up
  tabletPortrait: (strings: TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => css`
    @media (min-width: 768px) {
      ${css(strings, ...interpolations)};
    }
  `,
  // for tablet portrait and up
  tablet: (strings: TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => css`
    @media (min-width: 770px) and (max-width: 1250px) {
      ${css(strings, ...interpolations)};
    }
  `,
  tablet2: (strings: TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => css`
    @media (min-width: 600px) and (max-width: 769px) {
      ${css(strings, ...interpolations)};
    }
  `,
  tablet3: (strings: TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => css`
    @media (min-width: 770px) and (max-width: 821px) {
      ${css(strings, ...interpolations)};
    }
  `,
  // for tablet landscape and up
  tabletLandscape: (strings: TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => css`
    @media (min-width: 900px) {
      ${css(strings, ...interpolations)};
    }
  `,
  // for desktop and up
  desktop: (strings: TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => css`
    @media (min-width: 1200px) {
      ${css(strings, ...interpolations)};
    }
  `,
  // for big desktop and up
  big: (strings: TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => css`
    @media (min-width: 1800px) {
      ${css(strings, ...interpolations)};
    }
  `,
  // for lagrge desktop and up
  large: (strings: TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => css`
    @media (min-width: 1920px) {
      ${css(strings, ...interpolations)};
    }
  `
};
