import React, { createRef } from 'react';
import { getElements } from '../../../cms/utils';
import { Carousel, Col, Row } from 'antd';
import { SectionTitle, CarouselWrapper, RootCard } from './SuccessStoryStyle';
import SuccessStoryCard from '../Components/SuccessStoryCard';
import './OtherSuccessStories.css';
import { useMediaQuery } from 'react-responsive';
type OuterProps = {
  sectionData: any;
};
const OthersSuccessStories: React.FC<OuterProps> = ({ sectionData }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 728px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 1064px)' });
  const carouselRef = createRef<any>();
  if (sectionData.data.length === 0 || sectionData.section.length === 0) {
    return null;
  }
  const { data } = sectionData;
  const aImgs = getElements(data[0]);
  const aListe: any = [];

  for (let r: number = 0; r < aImgs.length / 2; r++) {
    let row = aImgs[r * 2];
    let row1 = aImgs[r * 2 + 1];
    aListe.push(
      <SuccessStoryCard
        link={`${row.description}&${row1.description}`}
        key={row.id}
        card={{
          cardimg1: row.logo,
          cardimg2: row1.logo,
          cardTitle: `${row.description} x ${row1.description}`
        }}
        cardIndex={0}
        startIndex={0}
        other={true}
      />
    );
  }
  const slidesToShow: number = (() => {
    if (isMobile) {
      return 1;
    } else if (isTablet) {
      return 2;
    } else {
      return 3;
    }
  })();
  return (
    <div>
      <Row>
        <Col lg={{ span: 8 }}>
          <SectionTitle>Voir d’autres success stories</SectionTitle>
        </Col>
      </Row>
      <CarouselWrapper>
        <Carousel
          ref={carouselRef}
          slidesToShow={slidesToShow}
          slidesToScroll={isMobile ? 1 : Math.floor(aImgs?.length / 3)}
          arrows
          dots={true}
          prevArrow={<></>}
          nextArrow={<></>}
        >
          {aListe.map((v: any) => (
            <RootCard key={v.id}>{v}</RootCard>
          ))}
        </Carousel>
      </CarouselWrapper>
    </div>
  );
};

export default OthersSuccessStories;
