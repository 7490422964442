import { Highlight as HighlightAIS } from 'react-instantsearch';
import styled, { media } from '../../../styled-components';

// block twin CARDST101
export const Root = styled.div`
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 40px;
  min-height: 217px;
  display: flex;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16), 0px 3px 3px -2px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  &:hover {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
  }
  ${media.mobile`
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 20px;
`};
`;

export const Cover = styled.div`
  padding: 24px 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.mobile`
    padding: 12px 6px;
  `};
`;

export const Logo = styled.img`
  width: 84px;
  height: 84px;
  border-radius: 4px;

  ${media.mobile`
    width: 60px;
    height: 60px;
  `};
`;

export const Informations = styled.div`
  flex: 1 1 auto;
  padding: 16px 22px 16px 0px;
  line-height: 32px;
  display: flex;
  flex-direction: column;

  ${media.mobile`
    padding: 12px;
  `};
`;

export const Name = styled.h3`
  margin-bottom: 9px;
  line-height: 32px;

  & > a {
    color: ${({ theme }) => theme.colors.grey900};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Punchline = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const Description = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
  flex: 1 0 auto;
  min-height: 92px;
  ${media.mobile`
    height: auto;
    width: calc(100vw - 150px);
  `};
`;

export const Highlight = styled(HighlightAIS)`
  & .ais-Highlight-highlighted {
    color: ${({ theme }) => theme.colors.primary};
    font-style: normal;
  }
`;

export const Metas = styled.div`
  margin-top: 16px;
  display: flex;
`;

export const Meta = styled.div`
  &:not(:first-child) {
    padding-left: 24px;
  }
`;

export const MetaName = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  text-transform: uppercase;

  font-family: Arial;
  font-size: 10px;
  font-weight: bold;
`;

export const MetaValue = styled.div`
  margin-top: 4px;
  font-size: 20px;
  font-family: Arial;
`;

export const Plus = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Tags = styled.div`
  height: 28px;
  margin: -2px;
  display: flex;
`;

export const Tag = styled.div`
  height: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grey900};
  font-family: proxima-nova;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  padding: 2px 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.grey300};
  border-radius: 100px;
  margin: 2px;
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;

  &:nth-child(3) {
    flex: 0 0 auto;
    width: 36px;
  }
`;
