// import original module declarations
import 'styled-components';

// and extend them!
declare module 'styled-components' {
  export interface DefaultTheme extends ThemeInterface {}
}

export default interface ThemeInterface {
  html: HtmlInterface;
  body?: BodyInterface;
  colors: ColorInterface;
  transitions: TransitionInterface;

  mainWidth: string;
  mainPaddingX: string;
  columnCount: number;
  columnWidth: number;
  gutter: number;
}

export interface HtmlInterface {
  fontSize: string;
  lineHeight: string;
  color: string;
  fontFamily: string;
  backgroundColor: string;
}

export interface BodyInterface {
  margin: string;
  padding: string;
}

export interface TransitionInterface {
  slow: string;
  default: string;
  fast: string;
}

export interface ColorInterface {
  black: string;
  white: string;

  primary: string;
  secondary: string;

  success: string;
  successDark: string;

  danger: string;
  dangerDark: string;

  important: string;

  info: string;

  grey900: string;
  grey700: string;
  grey500: string;
  grey300: string;
  grey100: string;
}

export const theme: ThemeInterface = {
  html: {
    fontSize: '16px',
    lineHeight: '1.5',
    color: '#071A39',
    fontFamily: '"IBM Plex Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif',
    backgroundColor: '#ffffff'
  },

  transitions: {
    slow: '.5s ease',
    default: '.25s ease',
    fast: '.125s ease'
  },

  mainWidth: `${66 * 12 + 24 * 11}px`,
  mainPaddingX: '16px',

  columnCount: 12,
  columnWidth: 66,
  gutter: 24,

  colors: {
    black: '#000000',
    white: '#ffffff',

    primary: '#00a1e0',
    secondary: '#fed800',

    success: '#71df98',
    successDark: '#5ac47e',

    danger: '#f93434',
    dangerDark: '#ffb8b8',

    important: '#ffdd8a',

    info: '#a1b0ff',

    grey900: '#3d4a57',
    grey700: '#8e9faf',
    grey500: '#b2bcc5',
    grey300: '#e0e7ec',
    grey100: '#f6f7f8'
  }
};
