import classNames from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

type HTMLButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface ButtonProps {
  disabled?: boolean;
  label?: string;
  to?: LinkProps['to'];
  href?: string;
  className?: string;
  kind?: 'primary' | 'secondary' | 'tertiary' | 'secondary-light';
  children?: ReactNode;
  onClick?: () => void;
  type?: HTMLButtonProps['type'];
  name?: string;
  isLoading?: boolean;
  style?: CSSProperties;
}

const Spinner = () => (
  <div className="ds-btn-loading_mask ds-btn-loading_mask--primary">
    <svg
      width="24"
      height="24"
      className="ds-icon"
      focusable="false"
      aria-label="[Inserer un contenu textuel explicite]"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-742.000000, -720.000000)" fill="#071A39" fill-rule="nonzero">
          <g transform="translate(241.000000, 361.000000)">
            <g transform="translate(441.000000, 347.000000)">
              <g transform="translate(60.000000, 12.000000)">
                <path
                  d="M2.779008,2.779008 C2.538288,2.538288 2.145944,2.536856 1.924464,2.795384 C0.833176,4.069232 0.1663112,5.658664 0.02721504,7.34068 C-0.1288704,9.22816 0.3897312,11.10976 1.490744,12.6508 C2.59176,14.19176 4.203776,15.29216 6.03996,15.75616 C7.876144,16.22016 9.81736,16.01776 11.51832,15.1848 C13.2192,14.35192 14.56952,12.94256 15.3288,11.20752 C16.08816,9.47248 16.20736,7.524352 15.66512,5.70972 C15.12296,3.89508 13.95456,2.331656 12.36784,1.297616 C10.95384,0.3761328 9.28936,-0.0716704 7.613952,0.00930936 C7.27392,0.0257444 7.034472,0.336556 7.077024,0.6743136 C7.119584,1.012072 7.428096,1.248416 7.768328,1.236752 C9.15344,1.18928 10.52536,1.568368 11.69472,2.330456 C13.03696,3.205144 14.02528,4.527648 14.48392,6.062648 C14.94264,7.597648 14.84176,9.2456 14.19944,10.7132 C13.55712,12.18088 12.41496,13.37304 10.97616,14.0776 C9.53736,14.78216 7.895224,14.95344 6.342,14.56096 C4.788768,14.1684 3.425168,13.2376 2.493824,11.93408 C1.562472,10.63056 1.123784,9.03888 1.255816,7.44228 C1.370856,6.051224 1.912616,4.735024 2.799424,3.669976 C3.017248,3.40836 3.019728,3.019728 2.779008,2.779008 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export const Button = ({
  disabled,
  label,
  to,
  href,
  className,
  kind = 'primary',
  children,
  type,
  onClick,
  name,
  isLoading = false,
  style
}: ButtonProps) => {
  const content = label || children;

  const classes = classNames(
    'ds-btn',
    {
      'ds-btn--primary': kind === 'primary',
      'ds-btn--secondary': kind === 'secondary',
      'ds-btn--tertiary': kind === 'tertiary',
      'ds-btn--secondary-light': kind === 'secondary-light'
    },
    {
      'ds-btn-loading': isLoading
    },
    className
  );

  if (to) {
    return (
      <Link className={classes} to={to} style={style} onClick={onClick}>
        {isLoading && <Spinner />}
        {content}
      </Link>
    );
  } else if (href) {
    return (
      <a className={classes} href={href} style={style}>
        {isLoading && <Spinner />}
        {content}
      </a>
    );
  } else {
    return (
      <button
        className={classes}
        onClick={onClick}
        // eslint-disable-next-line react/button-has-type
        type={type}
        name={name}
        disabled={disabled}
        style={style}
      >
        {isLoading && <Spinner />}
        {content}
      </button>
    );
  }
};
