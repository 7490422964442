import * as React from 'react';
import ButtonYellow1 from '../Buttons/ButtonYellow1';
import { Data1 } from '../SectionPreviewListStartups/Style';
import { BigCard, Card, CardWrapper, ContainerRoots, RootCard, TagCol } from './Style';

type State = {
  value: string;
  anchored: boolean;
  menu: number | null;
};
type OuterProps = {
  // tslint:disable-next-line:no-any
  sectionData: any;
};
type InnerProps = OuterProps;

class Template extends React.PureComponent<InnerProps, State> {
  constructor(props: InnerProps) {
    super(props);
  }
  // tslint:disable-next-line:no-any
  getTags = (cmstags: any) => {
    // tslint:disable-next-line:no-any
    const tags: any = cmstags.map((tag: any, index: number) => {
      return (
        <div key={tag.slug}>
          <ButtonYellow1
            key={index}
            size="11"
            color="#4F4F4F"
            fontWeight="600px"
            bgcolor="white"
            border="solid 1px #FFCD00"
            href={`/tag/${tag.slug}`}
          >
            <span lang="fr" style={{ textTransform: 'uppercase' }}>
              {tag.name}
            </span>
          </ButtonYellow1>
        </div>
      );
    });
    return tags;
  };

  render() {
    const { sectionData } = this.props;
    if (sectionData.data.length === 0) {
      return null;
    }
    const { data, section } = sectionData;

    // tslint:disable-next-line:no-any
    const aListe: any = [];
    let r = 0;
    while (r < data.length) {
      let row = data[r];
      let tags = this.getTags(row.cmstags);
      aListe.push(
        <CardWrapper key={row.id}>
          <BigCard className="Startup-boxshadow" style={{ height: '100%' }}>
            <div
              style={{
                height: '325px',
                background: row.logo ? `url('${row.logo}') no-repeat center/contain` : undefined,
                backgroundSize: 'cover'
              }}
            >
              <a
                href={`actualites${row.slug}`}
                style={{ display: 'block', width: '100%', height: '100%' }}
                aria-label={`actualites${row.slug}-logo`}
              />
            </div>
            <div style={{ padding: '33px' }}>
              <h2 className="H2-charte" style={{ margin: '0px 0px 15px 0px', maxWidth: '607px' }}>
                <a href={`actualites${row.slug}`}>{row.title}</a>
              </h2>
              <Data1 style={{ marginBottom: '25px' }}>
                {row.author && <span>{row.author}&nbsp;&nbsp;&nbsp;</span>} {row.updated}
              </Data1>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>{tags}</div>
            </div>
          </BigCard>
        </CardWrapper>
      );

      r = r + 1;
      let r2 = 0;
      while (r < data.length) {
        // && r2 < 4
        row = data[r];
        let rtags = this.getTags(row.cmstags);

        aListe.push(
          <CardWrapper key={row.id}>
            <Card className="Startup-boxshadow" style={{ height: '100%', marginBottom: '10px' }}>
              <div
                style={{
                  height: '201px',
                  background: row.logo ? `url('${row.logo}') no-repeat center/contain` : undefined,
                  backgroundSize: 'cover'
                }}
              >
                <a
                  href={`actualites${row.slug}`}
                  style={{ display: 'block', width: '100%', height: '100%' }}
                  aria-label={`actualites${row.slug}-logo`}
                />
              </div>
              <div style={{ padding: '23px' }}>
                <h2 className="H4-charte" style={{ margin: '0px 0px 15px 0px' }}>
                  <a href={`actualites${row.slug}`}>{row.title}</a>
                </h2>
                <div className="Data1" style={{ marginBottom: '20px' }}>
                  {row.author && <span>{row.author}&nbsp;&nbsp;&nbsp;</span>} {row.updated}
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>{rtags}</div>
              </div>
            </Card>
          </CardWrapper>
        );
        r2 = r2 + 1;
        r = r + 1;
      }
      if (r >= data.length) {
        break;
      }
    }
    // tslint:disable-next-line:no-any
    const tagsresume: any = this.getTags(JSON.parse(section.tagsresume));
    return (
      <ContainerRoots>
        <TagCol>{tagsresume}</TagCol>
        <RootCard>{aListe}</RootCard>
      </ContainerRoots>
    );
  }
}

export default Template;
