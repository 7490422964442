import { HeaderFiltersContainer, FilterCategory, CategoryTitle } from '../HeaderStyle';

const FiltersHeaderContainer = () => (
  <HeaderFiltersContainer>
    <FilterCategory className="filter-category">
      <CategoryTitle className="filter-category-title">
        <a href="/search/thematiques">Thématique</a>
      </CategoryTitle>
      <ul role="list">
        <li>
          <a href="/search/thematique/Deeptech/?n=1&refinementValue=Deeptech">Deeptech</a>
        </li>
        <li>
          <a href="/search/thematique/Martech+%2F+Adtech+%2F+Salestech/?n=1&refinementValue=Martech%2520%252F%2520Adtech%2520%252F%2520Salestech">
            Martech / Adtech / Salestech
          </a>
        </li>
        <li>
          <a href="/search/thematique/IA+%2F+Big+Data/?n=1&refinementValue=IA%2520%252F%2520Big%2520Data">
            IA / Big Data
          </a>
        </li>
        <li>
          <a href="/search/thematique/Health+Tech+%2F+Medtech/?n=1&refinementValue=Health%2520Tech%2520%252F%2520Medtech">
            Health Tech / Medtech
          </a>
        </li>
        <li>
          <a href="/search/thematique/Smart+Industry/?n=1&refinementValue=Smart%2520Industry">
            Smart Industry
          </a>
        </li>
        <li>
          <a href="/search/thematique/Fin+Tech/?n=1&refinementValue=Fin%2520Tech">Fin Tech</a>
        </li>
        <li>
          <a href="/search/thematique/E-Commerce/?n=1&refinementValue=E-Commerce">E-Commerce</a>
        </li>
        <li>
          <a href="/search/thematique/Transportation/?n=1&refinementValue=Transportation">
            Transportation
          </a>
        </li>
        <li>
          <a href="/search/thematique/Food+Tech+%2F+Agritech/?n=1&refinementValue=Food%2520Tech%2520%252F%2520Agritech">
            Food Tech / Agritech
          </a>
        </li>
        <li>
          <a href="/search/thematique/MarketPlace/?n=1&refinementValue=MarketPlace">MarketPlace</a>
        </li>
      </ul>
    </FilterCategory>
    <FilterCategory className="filter-category">
      <CategoryTitle className="filter-category-title">
        <a href="/search/region">Région</a>
      </CategoryTitle>
      <ul>
        <li>
          <a href="/search/region/Auvergne-Rhône-Alpes/?n=1&refinementValue=Auvergne-Rh%25C3%25B4ne-Alpes">
            Auvergne-Rhone-Alpes
          </a>
        </li>
        <li>
          <a href="/search/region/Bourgogne-Franche-Comté/?n=1&refinementValue=Bourgogne-Franche-Comt%25C3%25A9">
            Bourgogne-Franche-Comté
          </a>
        </li>
        <li>
          <a href="/search/region/Bretagne/?n=1&refinementValue=Bretagne">Bretagne</a>
        </li>
        <li>
          <a href="/search/region/Centre-Val+de+Loire/?n=1&refinementValue=Centre-Val%2520de%2520Loire">
            Centre-Val de Loire
          </a>
        </li>
        <li>
          <a href="/search/region/Corse/?n=1&refinementValue=Corse">Corse</a>
        </li>
        <li>
          <a href="/search/region/Grand+Est/?n=1&refinementValue=Grand%2520Est">Grand Est</a>
        </li>
        <li>
          <a href="/search/region/Hauts-de-France/?n=1&refinementValue=Hauts-de-France">
            Hauts-de-France
          </a>
        </li>
        <li>
          <a href="/search/region/Île-de-France/?n=1&refinementValue=%25C3%258Ele-de-France">
            Île-de-France
          </a>
        </li>
        <li>
          <a href="/search/region/Normandie/?n=1&refinementValue=Normandie">Normandie</a>
        </li>
        <li>
          <a href="/search/region/Nouvelle-Aquitaine/?n=1&refinementValue=Nouvelle-Aquitaine">
            Nouvelle-Aquitaine
          </a>
        </li>
      </ul>
    </FilterCategory>
    <FilterCategory className="filter-category">
      <CategoryTitle className="filter-category-title">
        <a href="/search/cities">Ville</a>
      </CategoryTitle>

      <ul>
        <li>
          <a href="/search/city/Paris/?n=1&refinementValue=Paris">Paris</a>
        </li>
        <li>
          <a href="/search/city/Lyon/?n=1&refinementValue=Lyon">Lyon</a>
        </li>
        <li>
          <a href="/search/city/Toulouse/?n=1&refinementValue=Toulouse">Toulouse</a>
        </li>
        <li>
          <a href="/search/city/Nantes/?n=1&refinementValue=Nantes">Nantes</a>
        </li>
        <li>
          <a href="/search/city/MONTPELLIER/?n=1&refinementValue=MONTPELLIER">Montpellier</a>
        </li>
        <li>
          <a href="/search/city/Lille/?n=1&refinementValue=Lille">Lille</a>
        </li>
        <li>
          <a href="/search/city/MARSEILLE/?n=1&refinementValue=MARSEILLE">Marseille</a>
        </li>
        <li>
          <a href="/search/city/Bordeaux/?n=1&refinementValue=Bordeaux">Bordeaux</a>
        </li>
        <li>
          <a href="/search/city/Boulogne-Billancourt/?n=1&refinementValue=Boulogne-Billancourt">
            Boulogne-Billancourt
          </a>
        </li>
        <li>
          <a href="/search/city/Grenoble/?n=1&refinementValue=Grenoble">Grenoble</a>
        </li>
      </ul>
    </FilterCategory>
    <FilterCategory className="filter-category">
      <CategoryTitle className="filter-category-title">
        <a href="/search/metier">Métiers Adressés</a>
      </CategoryTitle>
      <ul>
        <li>
          <a href="/search/metier/Marketing+%2F+Communication/?n=1&refinementValue=Marketing%2520%252F%2520Communication">
            Marketing / Communication
          </a>
        </li>
        <li>
          <a href="/search/metier/Finance+%2F+Administration/?n=1&refinementValue=Finance%2520%252F%2520Administration">
            Finance / Administration
          </a>
        </li>
        <li>
          <a href="/search/metier/Logistique+%2F+Opérations/?n=1&refinementValue=Logistique%2520%252F%2520Op%25C3%25A9rations">
            Logistique / Opérations
          </a>
        </li>
        <li>
          <a href="/search/metier/Ressources+Humaines/?n=1&refinementValue=Ressources%2520Humaines">
            Ressources Humaines
          </a>
        </li>
        <li>
          <a href="/search/metier/IT+%2F+Digital/?n=1&refinementValue=IT%2520%252F%2520Digital">
            IT / Digital
          </a>
        </li>
        <li>
          <a href="/search/metier/Conception+%2F+R%26D/?n=1&refinementValue=Conception%2520%252F%2520R%2526D">
            {'Conception / R&D'}
          </a>
        </li>
        <li>
          <a href="/search/metier/Juridique/?n=1&refinementValue=Juridique">Juridique</a>
        </li>
        <li>
          <a href="/search/metier/Vente+%2F+Service+Client/?n=1&refinementValue=Vente%2520%252F%2520Service%2520Client">
            Vente / Service Client
          </a>
        </li>
        <li>
          <a href="/search/metier/Production+%2F+Maintenance/?n=1&refinementValue=Production%2520%252F%2520Maintenance">
            Production / Maintenance
          </a>
        </li>
        <li>
          <a href="/search/metier/Achats/?n=1&refinementValue=Achats">Achats</a>
        </li>
      </ul>
    </FilterCategory>
    <FilterCategory className="filter-category">
      <CategoryTitle className="filter-category-title">
        <a href="/search/sectors">Secteur</a>
      </CategoryTitle>
      <ul>
        <li>
          <a href="/search/sector/Santé/?n=1&refinementValue=Sant%25C3%25A9">Santé</a>
        </li>
        <li>
          <a href="/search/sector/Finance/?n=1&refinementValue=Finance">Finance</a>
        </li>
        <li>
          <a href="/search/sector/Alimentaire/?n=1&refinementValue=Alimentaire">
            Energie / Utilities
          </a>
        </li>
        <li>
          <a href="/search/sector/Transport+%2F+Logistique/?n=1&refinementValue=Transport%2520%252F%2520Logistique">
            Transport / Logistique
          </a>
        </li>
        <li>
          <a href="/search/sector/Alimentaire/?n=1&refinementValue=Alimentaire">Alimentaire</a>
        </li>
        <li>
          <a href="/search/sector/Industrie/?n=1&refinementValue=Industrie">Industrie</a>
        </li>
        <li>
          <a href="/search/sector/Immobilier/?n=1&refinementValue=Immobilier">Immobilier</a>
        </li>
        <li>
          <a href="/search/sector/Sécurité+%2F+Défense/?n=1&refinementValue=S%25C3%25A9curit%25C3%25A9%2520%252F%2520D%25C3%25A9fense">
            Sécurité / Défense
          </a>
        </li>
        <li>
          <a href="/search/sector/Education+%2F+Formation/?n=1&refinementValue=Education%2520%252F%2520Formation">
            Education / Formation
          </a>
        </li>
        <li>
          <a href="/search/sector/Telecommunications/?n=1&refinementValue=Telecommunications">
            Telecommunications
          </a>
        </li>
      </ul>
    </FilterCategory>
  </HeaderFiltersContainer>
);

export default FiltersHeaderContainer;
