import React from 'react';
import { Row, Col, Card } from 'antd';
import { Helmet } from 'react-helmet';
import participation from './img/part.png';
import sight from './img/sight.png';
import poppins_logo from './img/poppins_logo.jpg';
import allianz_logo from './img/alianz_logo.png';
import Scaleway from './img/scaleway_logo.png';
import Quandela from './img/quandela_logo.png';
import IMA from './img/ima_logo.png';
import { SectionTitle, PRegular } from './SuccessStoryStyle';
import OthersSuccessStories from './OthersSuccessStories';
import { loader } from 'graphql.macro';
import { HomemainQuery, HomemainQueryVariables } from '../../../operation-result-types';
import { processDataTemplate } from '../../../cms/utils';
import { useQuery } from '@apollo/client';
import './SuccessStory.css';
import CustomBreadcrumb from 'src/Components/Breadcrumb/CustomBreadcrumb';
const HOMEMAIN_QUERY = loader('../../Home/Modules/HomeMain/homemain.query.gql');
const aSectionsLoad = ['SuccessStories'];
const aSectionsLoadType = ['data'];
const aContentcodes = ['hp-header', 'hp-under-header', 'hp-AppelProjet'];
const successStoriesLocalData = [
  {
    route: 'Poppins&Allianz',
    title: 'Poppins x Allianz',
    // headerImage: airbusImage,
    startup: {
      name: 'Poppins',
      logo: poppins_logo,
      description:
        'Poppins (ex-Mila) est une solution de thérapie digitale complète pour les enfants "DYS" (dyslexie, dysorthographie, dyscalculie, etc). La startup propose un programme complet qui vise à accompagner l\'enfant dans le dépassement de ses troubles de l\'apprentissage',
      createdAt: '2018',
      totalAmount: '4,1M€',
      labels: [participation]
    },
    organization: {
      name: 'Allianz',
      logo: allianz_logo,
      description:
        "Allianz est un groupe d'assurances, troisième assureur et huitième gestionnaire d'actifs au monde. Le groupe est diversifié entre l'assurance-dommage et l'assurance-vie et est actif en Allemagne, en Suisse, en France et en Italie.",
      createdAt: '1890'
    },
    problematic:
      "Pour mieux accompagner ses adhérents sur des sujets autour de la parentalité et du handicap, notamment sur la question des troubles de l'apprentissage, l'assureur Allianz cherchait des solutions innovantes à intégrer à son éventail de produits et services",
    solution:
      "La rencontre avec la startup Poppins a permis à Allianz de lancer un premier test auprès d'un pool de collaborateurs (et de leurs enfants) en France. Suite à ce premier POC, l'assureur a opéré une prise de participation dans le capital de Poppins et offert à tous ses collaborateurs hexagonaux la possibilité d'accéder à la plateforme, faisant passer le nombre potentiel d'utilisateurs d'une cinquantaine à environ 300. Avant peut-être d'être proposée aux clients finaux d'Allianz ?"
  },
  {
    route: 'SightCall&Groupe%20IMA',
    title: 'SightCall x IMA',
    startup: {
      logo: sight,
      name: 'SightCall',
      description:
        'SightCall est une plateforme professionnelle de vidéo dans le cloud qui aide les acteurs du service à améliorer leurs résultats sans déployer de ressources inutiles sur le terrain. Leader mondial de l’Augmented Reality powered Video Assistance, la solution permet une interaction vidéo en 1 clic vers tout terminal, enrichie de nombreuses fonctionnalités immersives.',
      createdAt: '2010',
      totalAmount: '15M€',
      labels: ['innovation']
    },
    organization: {
      logo: IMA,
      name: 'Groupe IMA',
      description:
        'Le Groupe IMA (Inter Mutuelles Assistance) intervient pour aider les personnes en difficulté partout dans le monde. Sa mission est de leur apporter une assistance humaine, médicale ou technique, 24h/24 et 7J/7.',
      createdAt: '1981',
      totalAmount: '10B€',
      labels: ['insurance']
    },
    problematic:
      "Optimiser l'assistance aux personnes victimes de sinistres, telle est la mission du Groupe IMA (Inter Mutuelles Assistance). Pour ce faire, les sinistres des clients d’IMA et, plus précisément, d’IMH (la division habitat du groupe), étaient traditionnellement traités par des agents au téléphone. Portées par le besoin et la volonté d’innover pour améliorer le traitement des problématiques clients, les équipes innovation et métiers d’IMA et IMH se sont naturellement tournées vers une solution d’appels vidéo.",
    solution:
      'Parmi les solutions innovantes existantes, c’est la startup SightCall, sous l’impulsion du Hub de Bpifrance, qu’IMA a choisie. Retour l’histoire d’une collaboration entre startup et grand Groupe réussie, au service des utilisateurs (les agents) et des clients finaux (les assurés).'
  },
  {
    route: 'Scaleway&Quandela',
    title: 'Scaleway x Quandela',
    startup: {
      logo: Scaleway,
      name: 'Scaleway',
      description:
        "Scaleway est un fournisseur de services cloud et d'hébergement basé en France, offrant des solutions diverses telles que des serveurs dédiés et des instances virtuelles.",
      createdAt: '2015',
      totalAmount: '50M€',
      labels: ['cloud', 'hosting']
    },
    organization: {
      logo: Quandela,
      name: 'Quandela',
      description:
        'Quandela est une société spécialisée dans les technologies quantiques, développant des solutions pour la génération et la manipulation de photons individuels.',
      createdAt: '2017',
      totalAmount: '20M€',
      labels: ['quantum', 'technology']
    },
    problematic:
      'Scaleway, filiale du groupe Iliad, souhaite se positionner comme l’acteur européen de référence sur les sujets d’hébergement cloud d’infrastructures IA. Cette posture implique d’être en avance de phase sur les concurrents en s’appuyant sur des technologies de pointe pour innover et fournir à ses clients les meilleurs services du marché. Scaleway s’est donc intéressé à l’algorithmie quantique, qui offre une puissance de simulation qui demain pourrait devenir un incontournable pour façonner et héberger des modèles d’intelligence artificielle.',
    solution:
      'Du fait des actions d’intermédiation de Bpifrance le Hub, Scaleway a eu l’opportunité de rencontrer la scale-up Quandela, l’une des références françaises du quantique. Après plusieurs mois d’échange, les deux entités ont signé un partenariat stratégique, associant la technologie de Quandela et la robustesse de l’infrastructure de Scaleway pour créer un environnement propice à l’expérimentation des algorithmes quantiques. Scaleway met ainsi à disposition du public un service QaaS, propulsé par un simulateur de circuit exclusif de Quandela, offrant dès aujourd’hui à ses clients une expérience quantique premium.'
  },
  {
    route: '4',
    title: 'Poppins x Allianz',
    startup: {
      name: 'Poppins (ex-Mila)',
      description:
        "Poppins (ex-Mila) est une solution de thérapie digitale complète pour les enfants 'DYS' (dyslexie, dysorthographie, dyscalculie, etc). La startup propose un programme complet qui vise à accompagner l'enfant dans le dépassement de ses troubles de l'apprentissage.",
      createdAt: '2012',
      totalAmount: '8M€',
      labels: ['therapy', 'education']
    },
    organization: {
      name: 'Allianz',
      description:
        "Allianz est un groupe d'assurances, troisième assureur et huitième gestionnaire d'actifs au monde. Le groupe est diversifié entre l'assurance-dommage et l'assurance-vie et est actif en Allemagne, en Suisse, en France et en Italie.",
      createdAt: '1890',
      totalAmount: null,
      labels: ['insurance']
    },
    problematic:
      "Pour mieux accompagner ses adhérents sur des sujets autour de la parentalité et du handicap, notamment sur la question des troubles de l'apprentissage, l'assureur Allianz cherchait des solutions innovantes à intégrer à son éventail de produits et services.",
    solution:
      "La rencontre avec la startup Poppins a permis à Allianz de lancer un premier test auprès d'un pool de collaborateurs (et de leurs enfants) en France. Suite à ce premier POC, l'assureur a opéré une prise de participation dans le capital de Poppins et offert à tous ses collaborateurs hexagonaux la possibilité d'accéder à la plateforme, faisant passer le nombre potentiel d'utilisateurs d'une cinquantaine à environ 300. Avant peut-être d'être proposée aux clients finaux d'Allianz ?"
  }
];

function getSubstringAfterLastSlash(): string | null {
  const currentUrl: string = window.location.href;
  const lastIndex: number = currentUrl.lastIndexOf('/');
  if (lastIndex !== -1) {
    const substringAfterLastSlash: string = currentUrl.substring(lastIndex + 1);
    return substringAfterLastSlash;
  } else {
    return null;
  }
}
function getObjectByRoute(route: string) {
  return successStoriesLocalData.find((story) => story.route === route);
}

const SuccessStory: React.FC = () => {
  const route = getSubstringAfterLastSlash();
  const currentSuccessStory = getObjectByRoute(route!);

  const { data } = useQuery<HomemainQuery, HomemainQueryVariables>(HOMEMAIN_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      sections: JSON.stringify(aSectionsLoad),
      contentcodes: JSON.stringify(aContentcodes),
      page: 0
    }
  });
  const nodes = data?.cmsSectionContents ? data.cmsSectionContents.nodes : [];
  const successStoryData: any = {};
  aSectionsLoad.forEach((template, index) => {
    successStoryData[template] = processDataTemplate(nodes, template, aSectionsLoadType[index]);
  });

  return (
    <>
      <Helmet>
        <title>Success Stories</title>
        <meta name="description" content="leHub: Suc;cess factories" data-react-helmet="true" />
        <meta property="og:title" content="Success Stories" />
        <meta property="og:description" content="Success Stories" data-react-helmet="true" />
      </Helmet>

      <Row className="RootPageSuccessStory">
        <Col xs={{ span: 22, offset: 1 }}>
          <Row>
            <Col span={24}>
              <CustomBreadcrumb
                items={[
                  { label: 'Accueil', url: '/' },
                  { label: 'Success Story' },
                  { label: currentSuccessStory?.title }
                ]}
                offset={0}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={24}>
              <h1 className="title">Success Story</h1>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <h2 className="subtitle">{currentSuccessStory?.title}</h2>
            </Col>
          </Row>
          {/* <Row style={{ marginTop: 40 }}>
            <Col
              span={24}
              style={{
                backgroundImage: `url(${currentSuccessStory?.headerImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: 'white',
                padding: '20px',
                height: 400
              }}
            />
          </Row> */}
          <Row style={{ marginTop: 40 }}>
            <Col>
              <Row>
                <Col>
                  <SectionTitle>Contexte</SectionTitle>
                </Col>
              </Row>
              <Row>
                <Col sm={{ span: 24 }} lg={{ span: 12 }} style={{ display: 'flex' }}>
                  <Card className="success-card">
                    <Row>
                      <Col
                        style={{
                          background: `url(${currentSuccessStory?.startup.logo}) no-repeat center center / contain`,
                          height: 64,
                          width: 64,
                          borderRadius: '8px',
                          border: `1px solid var(--bleu-100, #DDE8FB)`,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          overflow: 'hidden'
                        }}
                      ></Col>
                      <Col span={10} offset={1}>
                        <h6 className="company-name">{currentSuccessStory?.startup.name}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ marginTop: 18 }}>
                        <PRegular>{currentSuccessStory?.startup.description}</PRegular>
                        <span className="info">
                          Création : {currentSuccessStory?.startup.createdAt}
                        </span>
                        <span className="info">
                          Montant total levé : {currentSuccessStory?.startup.totalAmount}
                        </span>
                        <div className="labels">
                          <span className="label-text">Labels :</span>
                          {currentSuccessStory?.startup?.labels?.map((label, index) => (
                            <div
                              key={(--index).toString()}
                              style={{
                                backgroundImage: `url(${label})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                width: 40,
                                height: 40,
                                display: 'inline-block',
                                marginLeft: 16
                              }}
                            />
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col sm={{ span: 24 }} lg={{ span: 12 }} style={{ display: 'flex' }}>
                  <Card className="success-card">
                    <Row>
                      <Col
                        style={{
                          background: `url(${currentSuccessStory?.organization.logo}) no-repeat center center / contain`,
                          height: 64,
                          width: 64,
                          borderRadius: '8px',
                          border: `1px solid var(--bleu-100, #DDE8FB)`,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          overflow: 'hidden'
                        }}
                      ></Col>
                      <Col span={10} offset={1}>
                        <h6 className="company-name">{currentSuccessStory?.organization.name}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ marginTop: 18 }}>
                        <PRegular>{currentSuccessStory?.organization.description}</PRegular>
                        <span className="info">
                          Création : {currentSuccessStory?.organization.createdAt}
                        </span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 40 }}>
            <Col>
              <SectionTitle>Problématique</SectionTitle>
            </Col>
            <Col span={24}>
              <PRegular>{currentSuccessStory?.problematic}</PRegular>
            </Col>
          </Row>
          <Row style={{ marginTop: 40 }}>
            <Col>
              <SectionTitle>Solution</SectionTitle>
            </Col>
            <Col span={24}>
              <PRegular>{currentSuccessStory?.solution}</PRegular>
            </Col>
          </Row>
          <Row style={{ marginTop: 40 }}>
            <Col span={24}>
              <OthersSuccessStories sectionData={successStoryData['SuccessStories']} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SuccessStory;
