import { InMemoryCache, ApolloClient, createHttpLink, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import App from './App';
import { instance as i18n } from './i18n';
import { ThemeProvider } from './styled-components';
import { theme } from './theme';
const GlobalStyle = createGlobalStyle`
  html {
    font-size: ${({ theme }) => theme.html.fontSize};
    line-height: ${({ theme }) => theme.html.lineHeight};
    color: ${({ theme }) => theme.colors.grey900};
    font-family: ${({ theme }) => theme.html.fontFamily};
    
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body {
    margin: 0;
    background-color: ${({ theme }) => theme.html.backgroundColor};
  }
`;

const httpLink = createHttpLink({
  uri: `${window.SERVER_DATA?.REACT_APP_BACKEND_URL || process.env.REACT_APP_BACKEND_URL}/graphql`
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  if (!localStorage.getItem('expires_at')) {
    return { headers };
  }

  const token = localStorage.getItem('access_token');
  const expiresAt = JSON.parse(localStorage.getItem('expires_at') as string);

  if (expiresAt < Date.now()) {
    return { headers };
  }

  // return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first'
    }
  }
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <ApolloProvider client={client}>
    <I18nextProvider i18n={i18n}>
      <Router>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <App children={undefined} />
        </ThemeProvider>
      </Router>
    </I18nextProvider>
  </ApolloProvider>
  // </React.StrictMode>
);
