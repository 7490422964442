import i18n from 'i18next';
// @ts-ignore
import LocizeBackend from 'i18next-locize-backend';
// @ts-ignore
import * as LocizeEditor from 'locize-editor';
import { initReactI18next } from 'react-i18next';

//@ts-ignore
export const instance = i18n.use(LocizeBackend).use(LocizeEditor).use(initReactI18next);
// .use(LanguageDetector)

//@ts-ignore
export default instance.init({
  lng: 'fr',
  fallbackLng: 'fr',
  whitelist: ['fr', 'en'],
  keySeparator: '.',
  debug: process.env.NODE_ENV !== 'production',
  appendNamespaceToCIMode: true,
  saveMissing:
    process.env.NODE_ENV === 'production' ||
    process.env.REACT_APP_LOCIZE_ADD_WORDS_IN_DEV === '1' ||
    window.SERVER_DATA?.REACT_APP_LOCIZE_ADD_WORDS_IN_DEV === '1',

  backend: {
    projectId: '3b4ca79e-0ffe-42bb-aca9-a24ad36b1104',
    apiKey: 'cad83b47-ba36-4077-9d99-362a159f2ad6',
    referenceLng: 'fr'
  },

  react: {
    wait: true,
    useSuspense: false
  }
});
