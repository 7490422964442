import * as React from 'react';

import { Root } from './PageLoaderStyle';
import { Spin } from 'antd';

export default () => (
  <Root>
    <Spin size="large" />
  </Root>
);
