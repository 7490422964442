import { Divider } from 'antd';
import * as React from 'react';
import { slide as Slide } from 'react-burger-menu';
import { withTranslation, WithTranslation } from 'react-i18next';
import { flowRight as compose } from 'lodash';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import {
  ContainerLogo,
  ContainerMenuHamburger,
  IconRightSchrunkenWidth,
  MenuHorizontal,
  Root,
  Wrapper,
  RootWrapper,
  TifLogo,
  ContainerLogoMobile
} from './HeaderStyle';
import imglogo from './img/Logo.png';
type State = {
  isMyMenuOpen: boolean;
  FiltersHeaderContainerOpen: boolean;
};
import logoTIF from './img/logo_tif.png';
import FiltersHeaderContainer from './components/FiltersHeaderContainer';
import './Header.css';

type InnerProps = WithTranslation & RouteComponentProps<{}>;

class Header extends React.PureComponent<InnerProps, State> {
  constructor(props: InnerProps) {
    super(props);
    this.state = {
      isMyMenuOpen: false,
      FiltersHeaderContainerOpen: false
    };
  }
  closeMenu = () => {
    this.setState({ isMyMenuOpen: false });
  };
  isMenuOpen = (state: { isOpen: boolean }) => {
    if (state.isOpen === this.state.isMyMenuOpen) {
      return;
    }
    this.setState({ isMyMenuOpen: state.isOpen });
  };
  render() {
    return (
      <RootWrapper>
        <ContainerMenuHamburger>
          <Slide isOpen={this.state.isMyMenuOpen} onStateChange={this.isMenuOpen}>
            <div className="menu-mobile-header-logo">
              <Link to="/" aria-label="header logo">
                <ContainerLogoMobile>
                  <img src={imglogo} alt="header logo" />
                </ContainerLogoMobile>
              </Link>
            </div>
            <div className="menu-item" onClick={this.closeMenu}>
              <Link to="/">Accueil</Link>
            </div>
            <div className="menu-item" onClick={this.closeMenu}>
              <Link to="/search">Trouver une startup</Link>
            </div>
            <div className="menu-item" onClick={this.closeMenu}>
              <Link to="/projectCalls">Appels d'offres</Link>
            </div>
            <div className="menu-item" onClick={this.closeMenu}>
              <Link to="/actualites">Actualités</Link>
            </div>
            <div className="menu-item" onClick={this.closeMenu}>
              <Link
                to="/search/label/TechInFab"
                aria-label="tech in fab"
                className="HMenu-logo-tif"
              >
                <TifLogo>
                  <img height={28} src={logoTIF} alt="tech in fab" />
                </TifLogo>
              </Link>
            </div>
          </Slide>
        </ContainerMenuHamburger>
        <Root id="header">
          <Wrapper>
            <MenuHorizontal>
              <Link to="/" aria-label="header logo">
                <ContainerLogo>
                  <img src={imglogo} alt="header logo" />
                </ContainerLogo>
              </Link>
              <Divider type="vertical" className="header_divider" />
              <div className="HMenuRoot">
                <Link to="/">Accueil</Link>
              </div>
              <div
                className="HMenuRoot HMenuSearch"
                onMouseEnter={() => this.setState({ FiltersHeaderContainerOpen: true })}
                onMouseLeave={() => this.setState({ FiltersHeaderContainerOpen: false })}
              >
                <Link to="/search">Trouver une startup</Link>
                {this.state.FiltersHeaderContainerOpen && <FiltersHeaderContainer />}
              </div>
              <div className="HMenuRoot">
                <Link to="/projectCalls">Appels d'offres</Link>
              </div>
              <div className="HMenuRoot">
                <a href="/actualites">Actualités</a>
              </div>
              <Link
                to="/search/label/TechInFab"
                aria-label="tech in fab"
                className="HMenu-logo-tif"
              >
                <TifLogo>
                  <img height={28} src={logoTIF} alt="tech in fab" />
                </TifLogo>
              </Link>
            </MenuHorizontal>
            <IconRightSchrunkenWidth
              style={{ alignItems: 'center', position: 'relative', zIndex: 20000 }}
            >
              <Link to="/" aria-label="header logo">
                <ContainerLogoMobile>
                  <img src={imglogo} alt="header logo" />
                </ContainerLogoMobile>
              </Link>
            </IconRightSchrunkenWidth>
          </Wrapper>
        </Root>
      </RootWrapper>
    );
  }
}
export default compose(withTranslation(['startup', 'generic']))(withRouter(Header));
