import styled, { media } from '../../../styled-components';
import LogoSVG from './Image.svg';

export const Image = styled.div`
  display: flex;
  display: flex;
  margin: 10px 5px 10px 100px;
  float: left;
  /* height: 100%; */
  ${media.mobile`
  display: block;
  margin: 0px 0px 0px 0px;
  flex-shrink: 0;
  `};
`;

export const Root = styled.div`
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)),
    drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12));
  display: flex;
  border-radius: 8px;
  max-height: 310px;
  ${media.mobile`
    min-height: 550px;
      `};

  ${media.tablet3`
    min-height: 350px;
      `};

  .ds-btn--secondary {
    background: var(--Bleu-050, #f5f7fb);
    &:hover {
      background: #071a39;
    }
  }
`;

export const Titre3 = styled.h2`
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 130%;
  /* or 47px */
  display: flex;
  align-items: center;
  color: #071a39;
  margin: 20px 0px 20px 0px;
  ${media.mobile`
    color: var(--bleu-500, #001D4A);
font-feature-settings: 'clig' off, 'liga' off;

/* H2 */
font-family: IBM Plex Sans Condensed;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 130%; /* 41.6px */
margin: 20px 0px 20px 0px;
  `};
  color: var(--bleu-500, #001d4a);
  font-feature-settings: 'clig' off, 'liga' off;
`;
export const LeftSide = styled.div`
  padding-left: 30px;
  padding-top: 10px;
  ${media.mobile`
  padding-left: 20px;
  padding-top: 0px;
  margin-top: 20px;
  `};
  ${media.tablet2`
  padding-left: 7px;
  padding-top: 0px;
  `};
`;

export const Intro1 = styled.p`
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 29px;
  /* or 161% */
  color: var(--bleu-500, #001d4a);

  ${media.mobile`
    color: #000;
font-feature-settings: 'clig' off, 'liga' off;

/* P1/Regular */
font-family: IBM Plex Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
margin-left: 10px;
width: 90%;
  `};
`;
export const ButtonStyle = styled.div`
  margin: -10px 20px 18px 0px;
  ${media.mobile`
  margin: 5px 20px 10px 0px;
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
  `};
`;
export const Logo = styled.img.attrs({
  src: LogoSVG
})`
  width: 275px;
  height: 220px;
  ${media.mobile`
  width: 113px;
  height: 111px;
      `};
  ${media.tablet2`
  width: 222px;
  height: 204px;
      `};
  ${media.tablet3`
  height: 195px;
      `};
`;
