import styled, { media } from '../../styled-components';
import LogoSVG from '../Logo.svg';

export const Viewer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  ${media.mobile`
    margin-left: 0;
  `};
`;

export const Logo = styled.img.attrs({
  src: LogoSVG
})`
  height: 60px;
`;

export const MenuHorizontal = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  @media screen and (max-width: 1300px) {
    display: none;
  }
`;

export const IconRightFullWidth = styled.div`
  display: flex;
  position: relative;
  justify-content: start;
  @media screen and (max-width: 1300px) {
    display: none;
  }
`;

export const IconRightSchrunkenWidth = styled.div`
  display: flex;
  @media screen and (min-width: 1301px) {
    display: none;
  }
`;

export const ContainerLogo = styled.div`
  margin-left: 24px;
  margin-top: 5px;
  & > img {
    height: 40px;
  }
`;
export const ContainerLogoMobile = styled.div`
  margin-top: 5px;
  & > img {
    height: 40px;
  }
`;

export const TifLogo = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerMenuHamburger = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: 1301px) {
    display: none;
  }
`;

export const HeaderMargin = styled.div`
  height: 10px;
  text-align: center;
  background-color: #fff;
  width: calc(100% + 30px);
  overflow: hidden;
  z-index: 250;
  top: 60px;
  bottom: 0px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12),
    0px 1px 0px rgba(0, 0, 0, 0.2);
  position: fixed;

  @media screen and (min-width: 1301px) {
    width: calc(100% + 0px);
    position: absolute;
  }
`;

export const RootWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 999;
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 32px 0 32px;
  width: 1159px;
`;

export const Option = styled.div`
  @media screen and (max-width: 599px) {
    display: none;
  }
  margin-right: 30px;
  vertical-align: middle;
`;

type SearchBarProps = {};

export const SearchBar = styled.div<SearchBarProps>`
  padding: 0 18px;
  flex: 1 1 auto;
`;

export const Item = styled.div`
  width: 210px;
  display: inline-block;
  padding: 9px 16px 10px;
  color: ${({ theme }) => theme.colors.grey900};
  font-family: Arial;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  transition: all ${({ theme }) => theme.transitions.default};
  margin: -5px -12px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }

  & > a {
    display: block;
    width: 210px;
    margin: -9px -16px -10px;
    padding: 9px 16px 10px;
    text-overflow: ellipsis;
    overflow: hidden;

    color: ${({ theme }) => theme.colors.grey900};

    &:hover {
      color: ${({ theme }) => theme.colors.grey900};
    }
  }

  & i {
    color: ${({ theme }) => theme.colors.grey900};
    margin-right: 16px;
    font-size: 16px;
  }
`;

export const Root = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px #010b1b26;
  height: 56px;
  display: flex;
  justify-content: center;
  margin: auto;
  z-index: 250;
  & > div {
    min-height: 100%;
  }
  @media screen and (max-width: 1300px) {
    // max-width: 430px;
    width: 100%;
    position: fixed;
  }
  & .ant-dropdown-menu {
    margin-top: 20px;
  }
`;

export const Cards = styled.div`
  margin: 0 auto;
  max-width: 1140px;
`;

export const Card = styled.div`
  width: 50%;
  display: inline-block;

  &:nth-child(even) {
    padding-right: 8px;
  }

  &:nth-child(odd) {
    padding-left: 8px;
  }

  ${media.mobile`
    width: 100%;
    display: block;
    padding: 0 16px !important;
  `};
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 24px;
`;

export const HeaderFiltersContainer = styled.div`
  box-shadow: 0 11px 11px rgba(33, 33, 33, 0.2);
  position: absolute;
  background-color: #ffffff;
  margin-top: 12px;
  margin-left: -5px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  display: flex;
  flex-direction: row;
`;
export const FilterCategory = styled.div`
  margin-top: 12px;
`;
export const CategoryTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
`;
