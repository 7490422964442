import styled from '../../styled-components';

export const Root = styled.div`
  flex: 1 1 auto;
  padding: 48px ${({ theme }) => theme.gutter}px 64px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Informations = styled.div`
  min-height: 25vh;
  flex: 0 0 auto;
`;

export const Title = styled.div`
  margin-top: 48px;
  font-size: 32px;
  font-weight: 300;
  text-align: center;

  max-width: 600px;
`;

export const Description = styled.div`
  margin-top: 16px;
  text-align: center;
  font-size: 16px;

  max-width: 600px;
`;
