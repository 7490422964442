import styled, { media } from '../../../styled-components';
import { Carousel } from 'antd';
export const RootCardContent = styled.div`
  margin-bottom: 22px;
  display: flex;
  flex-wrap: wrap;
`;

export const CardContent = styled.div`
  margin-right: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > a {
    color: #000000;
    display: block;
  }

  &:hover > i {
    color: auto;
  }
`;

export const CardContentWrapper = styled.div`
  margin-bottom: 20px;
  height: 219px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 200px;
`;

export const ImageVertical = styled.div`
  min-width: 284px;
  max-height: 733px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
  @media screen and (max-width: 1300px) {
    display: none;
  }
`;

export const Data1 = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  /* identical to box height, or 127% */
  text-transform: uppercase;
  color: #737373;
`;

export const CarouselWrapper = styled(Carousel)`
  /* background-color: red; */
  height: 515px;
  width: 100%;
  .ant-carousel .slick-dots-bottom {
    bottom: 0px;
  }
  .ant-carousel .slick-dots li button {
    margin-bottom: 0px;
    flex-shrink: 0;
    background: var(--bleu-700, gray);
    opacity: 0.4;
  }

  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: var(--bleu-500, #001d4a);
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover {
    font-size: inherit;
    color: currentColor;
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-prev:hover {
    left: -18px;
    z-index: 2;
    color: black;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    font-size: 22px;
  }

  .ant-carousel .slick-next,
  .ant-carousel .slick-next:hover {
    right: 10px;
    z-index: 2;
    color: black;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    font-size: 22px;
  }
  ${media.mobile`
  & > .ant-carousel .slick-slider .slick-list [aria-hidden='true'] div {
    display: none;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -15px;
  }
  .ant-carousel .slick-dots li button {
    margin-bottom: 100px;
    flex-shrink: 0;
    opacity: 0.4;
    border-radius: 100px;
    background: var(--bleu-700, #1E88E5);
    width: 8px;
    height: 8px;
    flex-shrink: 0;

  }

  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: var(--bleu-500, #001d4a);
    border-radius: 100px;
    width: 8px;
    height: 8px;
    flex-shrink: 0;
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover {
    font-size: inherit;
    color: currentColor;
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-prev:hover {
    left: -20px;
    z-index: 2;
    color: black;
    flex-shrink: 0;
    width: 0px;
    height: 0px;
  }

  .ant-carousel .slick-next,
  .ant-carousel .slick-next:hover {
    right: -20px;
    z-index: 2;
    color: black;
    flex-shrink: 0;
    width: 0px;
    height: 0px;
  }
`};
`;
export const Root = styled.div`
  border-radius: 4px;
  overflow: hidden;
  min-height: 150px;
  display: flex;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16), 0px 3px 3px -2px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  &:hover {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
  }
  ${media.mobile`
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 20px;
`};
`;
export const Cover = styled.div`
  padding: 4px 12px;
  display: inline-flex;
  align-items: flex-start;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
`;

export const Logo = styled.img`
  object-fit: contain;
  border: 1px #e9e9e9 solid;
  border-radius: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  width: 64px;
  height: 64px;
  border-radius: 8px;

  ${media.mobile`
    width: 60px;
    height: 60px;
  `};
  border: 1px solid var(--bleu-100, #dde8fb);
`;

export const Informations = styled.div`
  flex: 1 1 auto;
  padding: 0px 0px 0px 0px;
  line-height: 32px;
  display: flex;
  flex-direction: column;

  ${media.mobile`
    padding: 12px;
  `};
  color: #000;
`;

export const Name = styled.h3`
  margin-bottom: 45px;
  line-height: 32px;

  & > a {
    color: ${({ theme }) => theme.colors.grey900};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  font-feature-settings: 'clig' off, 'liga' off;
  /* H6 */
  font-family: IBM Plex Sans Condensed;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 26px */
  width: 184px;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
  width: 312px;
  border-radius: 8px;
  background: var(--Blanc, #fff);
  height: 160px;
  ${media.mobile`
  width: 85%;
  `};
  ${media.tablet`
  width: 300px;
  `};
  ${media.tablet2`
  width: 290px;
  `};
`;

export const RootCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  width: 980px;
  margin: 20px 0px 30px 50px;
  gap: 0px;
  ${media.tablet`
  margin: 0px 0px 16px 14px;
  width: 900px;
  gap: 0px;
  `};
  ${media.tablet2`
  margin: 0px 0px 16px 30px;
  width: 580px;
  gap: 0px;
  `};
  ${media.tablet3`
  margin: 0px 0px 16px 30px;
  width: 600px;
  gap: 0px;
  `};
  ${media.mobile`
  margin: -5px 0px 7px 16px;
  height: 482px;
  width: 100%;
  `};
`;
export const Tags = styled.div`
  height: 28px;
  margin: -2px;
  display: flex;
  ${media.mobile`
  flex-wrap: wrap;
  flex-direction: row;
  width : auto;
  height:auto;
`};
`;

export const Tag = styled.div`
  height: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grey900};
  font-family: proxima-nova;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  padding: 2px 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.grey300};
  border-radius: 100px;
  margin: 2px;
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;

  &:nth-child(3) {
    flex: 0 0 auto;
    width: 36px;
  }
  ${media.mobile`
  display: inline-block;
  text-overflow: clip;
  word-break:normal;
  white-space: nowrap;
  width: fit-content !important;
  &:nth-child(1) {
    flex: 0 0 auto;
    max-width: 190px;
  }
  &:nth-child(2) {
    flex: 0 0 auto;
    max-width: 160px;
  }
  &:nth-child(3) {
    flex: 0 0 auto;
    max-width: 36px;
  }
`};
`;

export const Card = styled.div`
  width: 300px;
  flex-shrink: 0;
  padding: 10px 0px 0px 10px;
  ${media.mobile` 
  padding: 5px 0px 0px 10px;
  width: 100%;
  `};
  ${media.tablet`
  flex-shrink: 0;
  width: 81%;
  padding: 0px 0px 0px 0px;
  `};
  ${media.tablet2`
  flex-shrink: 0;
  width: 81%;
  padding: 0px 0px 0px 0px;
  `};
`;
