'use strict';

export const pathImg: string = window.SERVER_DATA
  ? window.SERVER_DATA.storageUrl
  : process.env.REACT_APP_OBJECTSTORAGE_URL!;

export const environment: string = window.SERVER_DATA ? window.SERVER_DATA.environment : 'dev';
export const algoliaIds: Array<string> = [
  window.SERVER_DATA ? window.SERVER_DATA.algolia.appId : process.env.REACT_APP_ALGOLIA_APP_ID!,
  window.SERVER_DATA ? window.SERVER_DATA.algolia.apiKey : process.env.REACT_APP_ALGOLIA_API_KEY!
];
export const indexUsersName = window.SERVER_DATA
  ? window.SERVER_DATA.algolia.indexes.users
  : process.env.REACT_APP_ALGOLIA_INDICE_USERS!;

export const urlSimple: { pattern: RegExp } = {
  pattern:
    /^(?:(?:https?):\/\/)?(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}|(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
};

export const urlFormat = (url: string): string => {
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    return `http://${url}`;
  } else {
    return url;
  }
};

export const wait = (ms: number) => new Promise((r, j) => setTimeout(r, ms));

export const limitStartupOptions: number = 5;

export const getHeaders = () => {
  const token = localStorage.getItem('access_token');
  const expiresAt = JSON.parse(localStorage.getItem('expires_at') as string);
  // tslint:disable-next-line: no-any
  let headers: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  if (expiresAt >= Date.now()) {
    // only when is not expired send token
    headers[`Authorization`] = token ? `Bearer ${token}` : '';
  }

  return headers;
};
// tslint:disable-next-line:no-any
export const fetchQl = async (query: string = '', variables: any) => {
  const qlBody = JSON.stringify({ query, variables });

  const headers = getHeaders();
  try {
    let response = await fetch(
      `${window.SERVER_DATA?.REACT_APP_BACKEND_URL || process.env.REACT_APP_BACKEND_URL}/graphql`,
      {
        headers,
        method: 'POST',
        body: qlBody
      }
    );
    const datajson = await response.json();
    return datajson;
  } catch (err) {
    throw err;
  }
};

// function to process  preview file on upload antd component
export const getBase64 = (blob: Blob): any => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
